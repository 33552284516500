import { PHASE_INDICATORS } from "./phases";
import { IIndicator } from "./types";

const GSIGHT_PREFIX = 'gs_'
const IND_PREFIX = 'ind_'
const SUBIND_PREFIX = ''
const IND_SUFFIX = '_health'
// const SUBIND_PREFIX = 'subind_'

/**
 * Assign style value based on status 
 * @param status 
 * @returns 
 */
export const getStatusStyle = (status: string) => {

  let style = '';
  const statusVal = status?.toLowerCase()
  if (statusVal === 'green') {
    style = 'success'
  }
  else if (statusVal === 'yellow') {
    style = 'warning'
  }
  else if (statusVal === 'red') {
    style = 'danger'
  }
  else if (statusVal === 'gray') {
    style = 'light'
  }
  return style;
}

/**
 * Extract all gainsight fields, which are prefixed with the value of GSIGHT_PREFIX
 * @param data 
 * @param ignoreFields 
 * @returns 
 */
export const extractGsightFields = (data: any, ignoreFields: string[]): string[] => {
  let gsightFields = []
  for (let key of Object.keys(data)) {
    if (!ignoreFields.includes(key) && key.startsWith(GSIGHT_PREFIX)) {
      gsightFields.push(key)
    }
  }
  return gsightFields
}

/**
 * Extract all indicators and subindicators, and create a map to assign subindicators to indicator buckets
 * Indicators are prefixed with IND_PREFIX. 
 * Subindicators are prefixed with SUBIND_PREFIX followed by the indicator name, followed by the subindicator name
 * @param data 
 * @returns 
 */
export const extractIndicators = (data: any): any[] => {

  //console.log(`extracting indicators`, data);
  if (!data) return [];
  // console.log(`indicators`, Object.keys(data));
  let indicatorStatus: any[] = []
  let indicators = []
  let subindicators = []


  for (let phase of PHASE_INDICATORS) {
    // console.log(`phase`, phase);

    for (let stage of phase.stages) {
      // console.log(`stage`, stage, phase.stages);

      for (let indicator of stage.indicators) {
        // console.log(`indicator`, indicator, stage.indicators);
        // 12-11-2023: search indicator workstream; only show if wl_type == 'search'
        console.log('data.workload_type',data.workload_type,String(data.workload_type).toLocaleLowerCase().includes('search led'))
        if(String(
          indicator['id']).toLocaleLowerCase().includes('ind_search_health')
        ){
          if(String(data.workload_type).toLocaleLowerCase().includes('search led') || String(data.workload_type).toLocaleLowerCase().includes('search attach')){
              console.log('no need to skip, this is the right workload type')
          }else{  
            continue; // if not 'search led' or 'search attach', skip  
          }
          // end indicator_search_health
        }
        let mappedSubindicators = []
        for (let subindicator of indicator?.metrics as any[]) {
          mappedSubindicators.push({ label: subindicator?.label, definition: subindicator?.definition, business_value: subindicator?.business_value, name: subindicator?.id, status: data[subindicator?.id], reason: data[`${subindicator?.id}_why`] })
        }
        indicatorStatus.push({ id: indicator?.id, subindicators: mappedSubindicators, label: indicator?.label, business_value: indicator?.business_value, definition: indicator?.definition, stage: stage.id, status: data[indicator?.id] })
        
      }

    }

    // if (key.startsWith(IND_PREFIX) && !key.endsWith("_gs") && key.endsWith(IND_SUFFIX)) {
    //   indicators.push({ name: key.replace(IND_PREFIX, '').replace(IND_SUFFIX, ''), status: data[key] })
    // }
    // if (!key.startsWith(IND_PREFIX) && key.endsWith(IND_SUFFIX)) {
    //   subindicators.push({ name: key.replace(IND_SUFFIX, ''), status: data[key] })
    // }
  }
  // console.log(`found indicators`, indicators);
  // console.log(`found subindicators`, subindicators);
  // for (let indicator of indicators) {
  //   let mappedSubindicators = []
  //   for (let subindicator of subindicators) {
  //     const targetPrefix = `${indicator.name}_`
  //     if (subindicator.name.startsWith(targetPrefix)) {
  //       mappedSubindicators.push({ name: subindicator.name.replace(targetPrefix, ''), status: subindicator.status })
  //     }
  //   }
  //   indicatorStatus.push({ ...indicator, subindicators: mappedSubindicators })
  // }

  // console.log(`found indicatorStatus`, indicatorStatus);
  return indicatorStatus;
}

/**
 * Return the unique values of all indicator status found
 * @param indicators 
 * @returns 
 */
export const extractWLStatus = (indicators: IIndicator[]) => {
  const indicatorArr = indicators.map((ind) => ind.status)
  const indicatorSet = new Set(indicatorArr);
  return Array.from(indicatorSet)
}

export const formatDate = (date: Date): string => {
  if(!date) return 'N/A'
  return date.toISOString().split('T')[0].replaceAll('-','/')
}
/**
 * Convert field names to readable names. Remove prefixes, convert _ to spaces and upper case.
 * @param name 
 * @returns 
 */
export const getReadableName = (name: string) => {

  // return name.toUpperCase()
  const readableName = name.replace(GSIGHT_PREFIX, '').replace(IND_PREFIX, '').replace(SUBIND_PREFIX, '').replaceAll("_", " ");
  return readableName.toUpperCase();
}
export const getReadablReasonLines = (_reasons: string[] | string): string[] => {
  let reasons: any[] = []
  if(Array.isArray(_reasons)) reasons = _reasons
  else if( typeof _reasons !== "string" ) reasons = [_reasons]

  reasons = reasons.filter(x => x)
  let readableReasonLines = reasons?.map(reason => {
    return reason?.replace('{', '')?.replace('}', '')
  })
  return readableReasonLines;
}

export const getLabelFromColor = (color: string) => {
  if (color?.toLowerCase() === 'green') return 'On Track'
  else if (color?.toLowerCase() === 'yellow') return 'At Risk'
  else if (color?.toLowerCase() === 'red') return 'Unhealthy'
  else return 'Unknown'
}
export const getColorFromLabel = (label: string) => {
  console.log(`getColorFromLabel`, label);

  if (label == 'On Track') return 'Green'
  if (label == 'At Risk') return 'Yellow'
  if (label == 'Unhealthy') return 'Red'
  else return 'Unknown'
}