import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { useOktaAuth } from '@okta/okta-react';

const ProtectedRoute = ({ component: Component, ...rest }) => {
   const { oktaAuth, authState } = useOktaAuth();
   const location = useLocation();

   return (
      <Route
         {...rest}
         render={(props) => {
            if (authState?.isAuthenticated) {
               return <Component {...rest} {...props} />;
            } else {
               return (
                  <Redirect
                     to={{
                        pathname: "/",
                        search: location.search,
                        state: {
                           from: props.location,
                        },
                     }}
                  />
               );
            }
         }}
      />
   );
};

export default ProtectedRoute;
