import * as React from "react";
import ItemList from "../components/ItemList";
import {useState,useEffect} from "react";
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { LoginCallback, Security, useOktaAuth } from '@okta/okta-react';

function Home() {
   const [registerEmail,setRegisterEmail] = useState("");
   const [registerPassword,setRegisterPassword] = useState("");

   const { authState, oktaAuth } = useOktaAuth();

   const registerUser = function(){
      
   };


   useEffect(()=>{
      console.log('authState',authState)
   },[])

   return <div className="home-content">
            
            <div>
               <Row>
                  <Col>
                     <p>HELLO</p>
                  </Col>
               </Row>
               <Row xs={1} md={4} className="g-4" style={{zIndex:999}}>
               <Col onClick={()=>{alert('box1')}}>
                     <Card>
                           <Card.Img variant="top" src="https://images.unsplash.com/photo-1496449903678-68ddcb189a24?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80" />
                           <Card.Body>
                           <Card.Title>Box A</Card.Title>
                           <Card.Text>
                           rem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temp
                           </Card.Text>
                           </Card.Body>
                        </Card>
                     </Col>
                     <Col>
                        <Card>
                           <Card.Img variant="top" src="https://images.unsplash.com/photo-1466921583968-f07aa80c526e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80" />
                           <Card.Body>
                           <Card.Title>Box B</Card.Title>
                           <Card.Text>
                           rem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temp
                           </Card.Text>
                           </Card.Body>
                        </Card>
                     </Col>
                     <Col>
                        <Card>
                           <Card.Img variant="top" src="https://images.unsplash.com/photo-1470225620780-dba8ba36b745?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80" />
                           <Card.Body>
                           <Card.Title>Box C</Card.Title>
                           <Card.Text>
                           rem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temp
                           </Card.Text>
                           </Card.Body>
                        </Card>
                     </Col>
                     <Col>
                        <Card>
                           <Card.Img variant="top" src="https://images.unsplash.com/photo-1494253109108-2e30c049369b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80" />
                           <Card.Body>
                           <Card.Title>Box D</Card.Title>
                           <Card.Text>
                           rem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temp
                           </Card.Text>
                           </Card.Body>
                        </Card>
                     </Col>
                     
                  </Row>
               </div>
         </div>;
}

export default Home;
