import React, { useEffect, useState } from "react";
import { WorkloadsTable } from "../components/WorkloadsTable";
import { useOktaAuth } from '@okta/okta-react';
import { useAuth } from "../hooks/useAuth";
import {useLocation,useSearchParams,useHistory} from "react-router-dom";
import queryString from 'query-string';

function Feed() {
  const { authState } = useOktaAuth();
  const {login,logout,getUserWorkloads,user,getWorkloadsCtas} = useAuth();
  const [wlData,setWLdata] = useState([]);
  const navigate = useHistory();
  
  useEffect(()=>{
    console.log('authed',"authState");
    if (authState?.isAuthenticated) {
      //login(authState.idToken.idToken);
      
    }
    console.log('USER',user);
    //this makes sure the previous user session is cleared
  },[authState,login,logout,getUserWorkloads,user]);

  //handleWorkloadSelect(datum.workload_name, datum?.account_id,  datum?.account_name)
  //navigate.push("workload/:wl_id")
  
   return (
      <div className="feed-content">
        {true &&
                <WorkloadsTable label="Workloads" handleWorkloadSelect={(workload_name, account_id,  account_name)=>{
                }}  handleAccountSelect={()=>{}}></WorkloadsTable>
        }
      </div>
   );
}

export default Feed;
