import { ListGroup, OverlayTrigger, Popover } from 'react-bootstrap';
import { getReadableName, getReadablReasonLines, getStatusStyle } from '../models/utils';
import ExpandableCard from '@leafygreen-ui/expandable-card';
import AccordionItem from 'react-bootstrap/esm/AccordionItem';
import InlineDefinition from '@leafygreen-ui/inline-definition';
import { Body } from '@leafygreen-ui/typography';

type Props = {
  name: string;
  indicators: any[];
  isCurrentStage?: boolean;
}

export default function ProgressionStage(props: Props) {
  const {
    name,
    indicators,
    isCurrentStage,
    ...rest
  } = props

  const getSubindicators = (indicator: any) => {

    let subindicators = null
    if (indicator.subindicators) {
      subindicators = indicator.subindicators.map((subindicator: any, idx: number) => {
        let x = subindicator.reason ? getReadablReasonLines(subindicator.reason).map((line) => <span dangerouslySetInnerHTML={{__html:"- "+ String(line).replace(new RegExp('\\"', 'g'),'"').replace(/\\"/g, '"') + "<br/>"}}></span>) : <span>N/A</span>;
        return <ListGroup.Item key={idx} variant={getStatusStyle(subindicator.status)}>
          <b>{getReadableName(subindicator.label)}</b>
          {
            subindicator.status !== "Green" ? 
            <><br/> <br/>
            <b>Reason: </b><br/>
           { x }</> : null
          }
          
          </ListGroup.Item>
      })
    }
    else {
      subindicators = <ListGroup.Item variant={getStatusStyle("gray")}>None Available</ListGroup.Item>
    }
    return subindicators
  }
  // console.log(`Progression stage`, props)
  return (
   <>
    <ListGroup>
      <>
      {/* <ListGroup.Item  ><div><b>{name}</b> </div></ListGroup.Item> */}
        {indicators && indicators.length ? indicators.map((indicator, idx) => {
          return !isCurrentStage ? <ListGroup.Item  key={idx} variant={getStatusStyle(isCurrentStage ? indicator.status : "gray")}>
            <div>
              <span>{(indicator.label)}</span>
            </div></ListGroup.Item>

          : <OverlayTrigger  key={idx} rootClose trigger="click" placement="auto" overlay={
            <Popover id={`popover-positioned-right`} style={{"maxWidth": "400px"}}>
              <Popover.Header as="h3">{(indicator.label)} Details</Popover.Header>
              <Popover.Body>
                <Body><b>Definition: </b><span dangerouslySetInnerHTML={{__html:indicator.definition}}></span></Body><br/>
                <Body><b>Business Value: </b>{indicator.business_value}</Body><br/>
                <Body><b>Metrics</b></Body>
                <ListGroup>
                  {
                    getSubindicators(indicator)
                  }
                </ListGroup>
              </Popover.Body>
            </Popover>}
          >
            
            <ListGroup.Item as={AccordionItem} style={{ cursor: "pointer" }} key={idx} variant={getStatusStyle(isCurrentStage ? indicator.status : "gray")}>
              <div>
                <span>{(indicator.label)}</span>
                <span style={{right: "8px", position: "absolute"}}>
                  <i>See details</i>
                </span>
              </div>
            </ListGroup.Item>
          </OverlayTrigger>
        }) :  <ListGroup.Item as={AccordionItem} className="text-center" style={{ cursor: "pointer" }} key={0} >No Indicator Data for this Stage</ListGroup.Item>}
      </>
    </ListGroup></>
  )

}
