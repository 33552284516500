import { useOktaAuth } from '@okta/okta-react';
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import { useAuth } from "../hooks/useAuth";
import ExpandableCard from "@leafygreen-ui/expandable-card";
import { Accordion, Col, Row } from 'react-bootstrap';
import {  ListGroup } from "react-bootstrap";
import Stepper from "@leafygreen-ui/stepper";
import { Body, H2, H3, Link } from "@leafygreen-ui/typography";
import { Pipeline, Stage } from "@leafygreen-ui/pipeline";
import {  Table, TableHeader, Row as TRow, Cell } from '@leafygreen-ui/table';
import Badge from "@leafygreen-ui/badge";
import ProgressionStage from "../components/ProgressionStage";
import { extractIndicators } from "../models/utils";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faShareSquare } from '@fortawesome/free-regular-svg-icons';
import Icon from '@leafygreen-ui/icon';
import {Tabs,Tab} from "@leafygreen-ui/tabs";
import Parser from 'html-react-parser';
import Button from '@leafygreen-ui/button';
import Carousel from 'react-bootstrap/Carousel';
import Card from "@leafygreen-ui/card";
import { read } from 'fs';
import { RadioBox, RadioBoxGroup } from "@leafygreen-ui/radio-box-group";
import Modal from "@leafygreen-ui/modal";
import Tooltip from '@leafygreen-ui/tooltip';
import { useAnalytics } from "use-analytics";

function WLV() {
  const { oktaAuth, authState } = useOktaAuth();

  const {login,logout,getUserWorkloads,getWorkload,getUser,user,authed,setSnooze} = useAuth();
  const p = useParams();
  let { wl_id } = useParams();
  const [wl,setwl] = useState({wl_id:null,workload_data:null});

  const [tabIndex2,setTabIndex2] = useState(0);

  const [snoozeModalShow,setSnoozeModalShow] = useState(false);
  const [snoozeWhy,setSnoozeWhy] = useState("");
  const [snoozeExp,setSnoozeExp] = useState("");
  const [snoozeComments,setSnoozeComments] = useState("");
  
  const [activeIndicator,setActiveIndicator] = useState("");
  const [indicatorItem,setIndicatorItem] = useState({label:null,definition:null,business_value:null,status:"",subindicators:[]});
  const [showIndicatorsCard, setShowIndicatorsCard] = useState(true)
  const [snoozeInfo,setSnoozeInfo] = useState({snoozed:false,reason:"",timeUpdated:"",expireAt:"",comments:""});
  const analytics = useAnalytics();

  useEffect(()=>{
    if((sessionStorage.getItem('og_user'))){
      analytics.identify(String(sessionStorage.getItem('og_user')),{company:"MongoDB"}, () => {
        analytics.page().then(()=>{console.log('tracked page visit')});
      })
    }
  },[sessionStorage.getItem('og_user')])

  useEffect(()=>{
    if(authState?.isAuthenticated){
      getWorkload(wl_id).then((x)=>{
        setwl(x);
        setAccountClusters(x.account_clusters);
        setSnoozeInfo(x.snooze_info);
        console.log('account_clusters',x.account_clusters)
      });
    }
  },[authState,wl_id])

  useEffect(()=>{
    if(wl && wl.workload_data){
      let bad = extractIndicators(wl.workload_data).filter(indicator => indicator?.stage?.toLowerCase() === expand2consumption(wl?.workload_data?.workload_stage).toLowerCase()).filter(indicator => indicator?.status?.toLowerCase() === "red" || indicator?.status?.toLowerCase() === "yellow" )
      console.log('bad',bad)
      if(bad.length > 0){
        setIndicatorItem(bad[0]);
        setActiveIndicator(bad[0]['label'])
      }else{
        //no need for the card
        setShowIndicatorsCard(false)
      }
    }
    

  },[wl])

  let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  const renderRedIndicators = (datum: any) => {
    //console.log('RED|YELLOW',datum);
    let indicators = extractIndicators(datum)
    indicators = indicators?.filter(indicator => (indicator?.stage?.toLowerCase() === datum.workload_stage?.toLowerCase() || indicator?.stage?.toLowerCase() === datum.workload_phase?.toLowerCase()) && indicator.status === "Red")
    let results = indicators?.length > 0 ? indicators.map(indicator => { return <Badge className='text-truncate' variant="red">{indicator?.label}</Badge>}) : null
    return results
  }
  const renderYellowIndicators = (datum: any) => {
    //console.log('RED|YELLOW',datum);
    let indicators = extractIndicators(datum)
    indicators = indicators?.filter(indicator => (indicator?.stage?.toLowerCase() === datum.workload_stage?.toLowerCase() || indicator?.stage?.toLowerCase() === datum.workload_phase?.toLowerCase()) && indicator.status === "Yellow")
    let results = indicators?.length > 0 ? indicators.map(indicator => { return <Badge className='text-truncate' variant="yellow">{indicator?.label}</Badge>}) : null
    return results
  }
  const renderReasonsForIndicator = (it) =>{
    let readableReasons = [];
    it.subindicators.forEach((s)=>{
      if(s.reason){
        let r = s.reason.filter((x)=>x);
        readableReasons.push(r[0]);
      }
    })
    console.log('readableReasons',readableReasons);
    /**
     * 
     * 
    escapeQuotes: function(string) {
        return string.replace(/"/g, '\\"');
    },
    unescapeQuotes: function(string) {
        return string.replace(/\\"/g, '"');
    }
     */
    //"In the consumption stage, cluster observed no writes for 1.0 hours. This metric exceeds the Yellow threshold of 1 hour. "<a href=\"https://cloud.mongodb.com/v2/629e57a95c51ed27f1249e2b#clusters/detail/crm-workflow-prod\" target=\"_blank\">crm-workflow-prod</a>""
    return <div dangerouslySetInnerHTML={{__html:String(readableReasons[0]).replace(new RegExp('\\"', 'g'),'"').replace(/\\"/g, '"')}}>
      
    </div>
  }
  const renderStageIndicators = (stage: string, health: any) => {
    // let indicators = indicatorsByStage[stage]
    // let indicators = stage.toLowerCase() === health?.workload_stage?.toLowerCase() ? extractIndicators(health) : []
    let isCurrentStage = false
    if (['consumption'].includes(stage.toLowerCase())) {
      isCurrentStage = ['retain', 'expand', 'adopt'].includes(health?.workload_stage?.toLowerCase())
    }
    else {
      isCurrentStage = stage.toLowerCase() === health?.workload_stage?.toLowerCase()
    }
    let indicators = extractIndicators(health)
    indicators = indicators.filter(indicator => indicator?.stage?.toLowerCase() === stage.toLowerCase())
    return <ProgressionStage name={stage.toUpperCase()} isCurrentStage={isCurrentStage} indicators={indicators ? indicators : []}></ProgressionStage>
  }
  const renderNewIndicatorCard = (stage,health) =>{
    let indicators = extractIndicators(health)
    indicators = indicators.filter(indicator => indicator?.stage?.toLowerCase() === stage.toLowerCase());
    let bad_indicators = indicators.filter(indicator => indicator?.status?.toLowerCase() === "red" || indicator?.status?.toLowerCase() === "yellow" );
    console.log('bad_indicators',bad_indicators);
    const listItems = bad_indicators.map((item,i) =>
    <RadioBox style={{padding:"0.2em"}} value={item.label}
    onClick={()=>{
      setActiveIndicator(item.label);
      for(let i = 0; i < indicators.length; i++){
        let tmpI = indicators[i];
        if(tmpI.label == item.label){
          setIndicatorItem(tmpI);
          break;
        }
      }
    }}>
      {item.label + (item.status.toLowerCase() == "yellow"? " 🟡 ":" 🔴 ")}
  </RadioBox>
      );

    return <div>
      <br />
            
      <RadioBoxGroup size="compact" style={{width:"100%",margin:"0 auto"}} value={activeIndicator}>
        {listItems}
      </RadioBoxGroup>
      <br />
      {showIndicatorsCard &&
      <Row className={" justify-content-around"}>
      <Col>
        <Card style={{width:"92%",margin:"0 0"}}>
          <h4 style={{textAlign:"center"}}>{indicatorItem.label} {indicatorItem.status.toLowerCase() == "yellow" ?  <Badge className='text-truncate' variant="yellow">At Risk</Badge> :  <Badge className='text-truncate' variant="red">Unhealthy</Badge>}</h4>
          <p>
            <b>Definition:</b><br />
            <span dangerouslySetInnerHTML={{__html:indicatorItem.definition}}></span>
          </p>
          <p>
            <b>Business Value:</b><br />
            {indicatorItem.business_value}
          </p>
          <p>
            <b>Reasons:</b>
            {renderReasonsForIndicator(indicatorItem)}
          </p>
        </Card>
      </Col>
  </Row>
      }
      
    </div>
  };
  const getLabelFromColor = (color: string) => {
    if (color?.toLowerCase() === 'green') return 'On Track'
    else if (color?.toLowerCase() === 'yellow') return 'At Risk'
    else if (color?.toLowerCase() === 'red') return 'Unhealthy'
    else return 'Unknown'
  }
  const formatDate = (date: Date): string => {
    if(!date) return 'N/A'
    if(date instanceof Date){
      return date.toISOString().split('T')[0].replaceAll('-','/')
    }
    else{
      date = new Date(String(date))
      return date.toISOString().split('T')[0].replaceAll('-','/')
    }
  }
  function extractContent(s) {
    var span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  };
  const expand2consumption = function(s:string){
    if(String(s).toLowerCase() == "expand" || String(s).toLowerCase() == "adopt" || String(s).toLowerCase() == "retain"){
      return "Consumption";
    }else{
      return s;
    }
  };

  const [accountClusters,setAccountClusters] = useState([]);
  /** 
   * 
   * getAccountClusters(authState?.user, viewState?.filters, accountState?.activeWorkloads).then((response) => {
      setAccountClusters(response);
      console.log('clusters-xx',response);
    })
  */
  const getClusterDetails = function(c:any){
    let res = {cluster_phase:"Unknown"};
    for(let i = 0; i < accountClusters.length; i++){
      if(accountClusters[i]['cluster_id'] == c.cluster_id__c){
        res = accountClusters[i];
        break;
      }
    }
    return res;
  };
  const c_renderRedIndicators = (datum: any) => {
    let indicators = extractIndicators(datum);
    indicators = indicators?.filter(indicator => (indicator?.stage?.toLowerCase() === datum.cluster_stage?.toLowerCase() || indicator?.stage?.toLowerCase() === datum.cluster_phase?.toLowerCase()) && indicator.status === "Red")
    let results = indicators?.length > 0 ? indicators.map(indicator => { return <Badge className='text-truncate' variant="red">{indicator?.label}</Badge> }) : null
    return results
  }
  const c_renderYellowIndicators = (datum: any) => {
    console.log('WTF-DATUM',datum)
    let indicators = extractIndicators(datum)
    indicators = indicators?.filter(indicator => (indicator?.stage?.toLowerCase() === datum.cluster_stage?.toLowerCase() || indicator?.stage?.toLowerCase() === datum.cluster_phase?.toLowerCase()) && indicator.status === "Yellow")
    let results = indicators?.length > 0 ? indicators.map(indicator => { return <Badge className='text-truncate' variant="yellow">{indicator?.label}</Badge> }) : null
    return results
  }
  const renderClustersTable = function(){
      
      const element = <Row className="table-1">
          <br />
          <Table
            className="clusters-table"
            data={accountClusters}
            columns={[
              <TableHeader label="Name" sortBy={(datum: any) => datum.name} />,
              <TableHeader label="Project" sortBy={(datum: any) => datum.group_name} />,
              <TableHeader label="Instance Size" sortBy={(datum: any) => datum.instance_size__c} />,
              <TableHeader label="Create Date" sortBy={(datum: any) => formatDate(datum.cluster_created_date)} />,
              <TableHeader label="Phase" sortBy={(datum: any) => datum.cluster_full_details.cluster_phase} />,
              <TableHeader label="MongoDB Version" sortBy={(datum: any) => datum.cluster_db_version} />,
              <TableHeader label="Age" sortBy={(datum: any) => datum.cluster_full_details.cluster_age} />,
              <TableHeader label="Indicators" sortBy={(datum: any) => datum.cluster_full_details.cluster_age} />,
            ]}
          >
            {({ datum }: any) => (
              <TRow key={datum.id}>
                <Cell><Link href={"https://cloud.mongodb.com/v2/"+encodeURIComponent(datum.group_id)+"#clusters/detail/"+encodeURIComponent(datum.cluster_name)}>{(datum.cluster_name)}</Link></Cell>
                <Cell>{datum.group_name}</Cell>
                <Cell>{(datum.cluster_instance_size)}</Cell>
                <Cell>{formatDate(datum.cluster_created_date)}</Cell>
                <Cell>{datum.cluster_phase}</Cell>
                <Cell>{datum.cluster_db_version ? datum.cluster_db_version : ""}</Cell>
                <Cell>{datum.cluster_age ? datum.cluster_age : 0}</Cell>
                <Cell>
  <Badge className='mx-1' variant='blue' >Phase: {datum?.cluster_phase ? datum?.cluster_phase : 'Not Onboarded'}</Badge>
  <Badge className='mx-1' variant='blue' >Tier: {datum?.cluster_instance_size ? datum?.cluster_instance_size : 'unknown tier'}</Badge>
  {c_renderRedIndicators(datum)}
  {c_renderYellowIndicators(datum)}
                </Cell>
              </TRow>
            )}
          </Table>
        </Row>

      return element
    }

  const checkIsCurrentStage = function(stage,wl){
    let isCurrentStage = false
    if (['consumption'].includes(stage.toLowerCase())) {
      isCurrentStage = ['retain', 'expand', 'adopt'].includes(wl?.workload_stage?.toLowerCase())
    }
    else {
      isCurrentStage = stage.toLowerCase() === wl?.workload_stage?.toLowerCase()
    }
    return isCurrentStage;
  }
  const [tabIndex,setTabIndex] = useState(0);

   return (
      <div className="wlv-content">

<Modal open={snoozeModalShow} initialFocus="">
  <div>
    <Body>
      <h3><b>Snooze Workload</b></h3>
      <hr />
      <p>
        <b>Why?</b>
        <select value={snoozeWhy} style={{width:"100%"}} onChange={(e)=>{
          setSnoozeWhy(String(e.target.value))
        }}>
          <option value="">
            Choose One
          </option>
          <option value="seasonal">
Seasonal Event
          </option>

          <option value="bydesign">
Behavior is by design
          </option>

          <option value="nonprod">
Non-Prod Environment
          </option>

          <option value="supportengaged">
Consulting/Support Engaged
          </option>
        </select>
      </p>
      <p>
        <b>Expiration:</b>
        <select value={snoozeExp} style={{width:"100%"}} onChange={(e)=>{
          setSnoozeExp(String(e.target.value));
        }}>
          <option value="">Choose one</option>
          <option value="7">1 Week</option>
          <option value="14">2 Week</option>

          <option value="30">1 Month</option>
          <option value="60">2 Month</option>
        </select>
      </p>

      <p style={{width:"100%"}}>
        <b>Comments:</b>
        <textarea value={snoozeComments} onChange={(e)=>{
          setSnoozeComments(String(e.target.value));
        }} style={{width:"100%"}}></textarea>
      </p>
      <div id="modal-btns">
        <Button variant="primary" style={{float:"right"}} onClick={()=>{
          //
          if(snoozeWhy == "" && snoozeExp == "" && snoozeComments == ""){
            alert('Cant be empty')
          }else{
            setSnooze(wl?.workload_data?.workload_id,true, snoozeComments, snoozeWhy, snoozeExp).then(()=>{
              window.location.reload();
            });
          }
        }}>Snooze</Button>
        <Button variant="danger" style={{float:"right"}} onClick={()=>{
          setSnoozeModalShow(false);
        }}>Cancel</Button>
      </div>
    </Body>
  </div>
</Modal>
        <br />
        {!authState?.isAuthenticated &&
          <p>Sorry, please login to continue. <a href="/" target="_blank">Start Over</a></p>
        }
        {authState?.isAuthenticated && wl.workload_data &&
        <>
      <Row style={{textAlign:"left",marginLeft:"2em"}}>
        <H2>
          {wl?.workload_data?.workload_name} &nbsp;&nbsp;&nbsp;&nbsp;
          <Badge className='text-truncate' variant={ wl.workload_data?.workload_health?.toLowerCase() === 'gray' ? 'lightgray':  wl.workload_data?.workload_health?.toLowerCase()}>{getLabelFromColor( wl.workload_data?.workload_health ? wl.workload_data?.workload_health : 'N/A')}</Badge>
          {String(wl?.workload_data.workload_onboarding_status).toLocaleLowerCase() == "legacy" &&
            <Badge style={{marginLeft:"1em"}} variant="darkgray">Legacy</Badge>
          }
        </H2>
        
        <Body baseFontSize={16} className={"py-1"}><a target="_blank" href={`https://mongodb.lightning.force.com/lightning/r/Workload__c/${wl?.workload_data?.workload_id}/view`}>SFDC <Icon glyph="OpenNewTab" data-testid="glyph" /></a> <span style={{marginLeft:"1.5em",color:"#889397"}}>{wl?.workload_data?.account_name}</span></Body>
        
        <Tabs selected={tabIndex} aria-labelledby="something">
          <Tab
            name="Health Indicators"
            onClick={()=>{
              setTabIndex(0);
            }}
          >

            <Row>
              <Col>
                <div>{renderNewIndicatorCard(expand2consumption(String(wl?.workload_data?.workload_stage)),wl.workload_data)}</div>
              </Col>
            </Row>
            <Row><br /></Row>

            <Row  style={{width:"95%"}}>
        
        <Row className='mx-auto mb-4 w-100'>
          <Pipeline size="xsmall" className="w-100 my-pipeline">
            <Stage className="w-75">Onboarding</Stage>
            <Stage className="w-25">Consumption</Stage>
          </Pipeline>
          {/* <Col xs={9} className="px-2"><Badge variant="blue" className="w-100">Onboarding</Badge></Col>
          <Col xs={3}  className="px-2"><Badge  variant="blue" className="w-100">Consumption</Badge></Col> */}
        </Row>


        <Col>
          <ListGroup className='mb-4'>
            <ListGroup.Item style={{ cursor: "pointer" }} key={0} variant={ !(checkIsCurrentStage("plan",wl?.workload_data) && wl?.workload_data.workload_is_overdue) && wl?.workload_data?.workload_plan_date ? "success" : "gray"}>
              <div>
              <b>Plan</b> 
              {checkIsCurrentStage("plan",wl?.workload_data) && wl?.workload_data.workload_is_overdue &&
                <span style={{ position: "absolute", color:"red", fontWeight:"bold" }}>
                {wl?.workload_data?.workload_plan_date ? `(${formatDate(wl?.workload_data?.workload_plan_date)})` : ''}
                <Tooltip
                  align="top"
                  justify="start"
                  trigger={<span>⏰</span>}
                  triggerEvent="hover"
                  darkMode={true}
                >
                  Workload has been in stage for {wl?.workload_data.workload_days_in_stage} days. <br/>
                  (Expected: &lt;= 15 days)
                </Tooltip>
                </span>
              }
              {!wl?.workload_data.workload_is_overdue &&
                <span style={{ position: "absolute" }}>
                {wl?.workload_data?.workload_plan_date ? `(${formatDate(wl?.workload_data?.workload_plan_date)})` : ''}
                </span>
              }
              </div></ListGroup.Item>
          </ListGroup>

          {renderStageIndicators("plan", wl.workload_data)}
        </Col>
        <Col >
          <ListGroup className='mb-4'>
            <ListGroup.Item style={{ cursor: "pointer" }} key={0} variant={!(checkIsCurrentStage("build",wl?.workload_data) && wl?.workload_data.workload_is_overdue) && wl?.workload_data?.workload_build_date ? "success" : "gray"}>
              <div>
                <b>Build</b> 
                {checkIsCurrentStage("build",wl?.workload_data) && wl?.workload_data.workload_is_overdue &&
                <span style={{ position: "absolute", color:"red", fontWeight:"bold" }}>
                  {wl?.workload_data?.workload_build_date ? `(${formatDate(wl?.workload_data?.workload_build_date)})` : ''}
                  <Tooltip
                  align="top"
                  justify="start"
                  trigger={<span>⏰</span>}
                  triggerEvent="hover"
                  darkMode={true}
                >
                  Workload has been in stage for {wl?.workload_data.workload_days_in_stage} days. <br/>
                  (Expected: &lt;= 30 days)
                </Tooltip>
                </span>
                }
                {!wl?.workload_data.workload_is_overdue &&
                <span style={{ position: "absolute" }}>
                  {wl?.workload_data?.workload_build_date ? `(${formatDate(wl?.workload_data?.workload_build_date)})` : ''}
                </span>
                }
              </div></ListGroup.Item>
          </ListGroup>
          {renderStageIndicators("build", wl.workload_data)}
        </Col>
        <Col>
          <ListGroup className='mb-4'>
            <ListGroup.Item style={{ cursor: "pointer" }} key={0} variant={!(checkIsCurrentStage("launch",wl?.workload_data) && wl?.workload_data.workload_is_overdue) && wl?.workload_data?.workload_launch_date ? "success" : "gray"}>
              <div>
                <b>Launch</b> 
                {checkIsCurrentStage("launch",wl?.workload_data) && wl?.workload_data.workload_is_overdue &&
                <span style={{ position: "absolute", color:"red", fontWeight:"bold" }}>
                {wl?.workload_data?.workload_launch_date ? `(${formatDate(wl?.workload_data?.workload_launch_date)})` : ''}
                <Tooltip
                  align="top"
                  justify="start"
                  trigger={<span>⏰</span>}
                  triggerEvent="hover"
                  darkMode={true}
                >
                  Workload has been in stage for {wl?.workload_data.workload_days_in_stage} days. <br/>
                  (Expected: &lt;= 90 days)
                </Tooltip>
                </span>
                }
                {!wl?.workload_data.workload_is_overdue &&
                <span style={{ position: "absolute" }}>
                {wl?.workload_data?.workload_launch_date ? `(${formatDate(wl?.workload_data?.workload_launch_date)})` : ''}
                </span>
                }
             
              
              </div></ListGroup.Item>
          </ListGroup>
          {renderStageIndicators("launch", wl.workload_data)}
        </Col>
        <Col >
          <ListGroup className='mb-4'>
            <ListGroup.Item style={{ cursor: "pointer" }} key={0} variant={!(checkIsCurrentStage("consumption",wl?.workload_data) && wl?.workload_data.workload_is_overdue) && wl?.workload_data?.workload_adopt_date ? "success" : "gray"}>
              <div>
                <b>Production Moment</b> 
                {checkIsCurrentStage("consumption",wl?.workload_data) && wl?.workload_data.workload_is_overdue &&
                <span style={{ position: "absolute", color:"red", fontWeight:"bold" }}>
                    {wl?.workload_data?.workload_adopt_date ? `(${formatDate(wl?.workload_data?.workload_adopt_date)})` : ''}
                  ⏰</span>
                }
                {!wl?.workload_data.workload_is_overdue &&
                <span style={{ position: "absolute" }}>
                    {wl?.workload_data?.workload_adopt_date ? `(${formatDate(wl?.workload_data?.workload_adopt_date)})` : ''}
                </span>
                }
              </div></ListGroup.Item>
          </ListGroup>
          {renderStageIndicators("consumption", wl.workload_data)}
        </Col>

      </Row>

          </Tab>
          <Tab name="Clusters"
            onClick={()=>{
              setTabIndex(1);
            }}>
            <Row className={" justify-content-around"}>
              <Card style={{width:"95%"}}>

              {renderClustersTable()}
              </Card>
            </Row>
          </Tab>
          <Tab name="Workload Details"
            onClick={()=>{
              setTabIndex(2);
            }}>
            
            <Row className={" justify-content-around"}>
              <Card style={{width:"95%"}}>
                <Row>
                  
                  <h4>Workload Details</h4>
                  <br />
                  <Col>
                    <Body baseFontSize={16} className={"py-1"}><b>Submitted on</b> <br /> {wl.workload_data.workload_submit_date ? formatDate(wl.workload_data.workload_submit_date) : 'N/A'}</Body>
                    <Body baseFontSize={16} className={"py-1"}><b>Approved On</b> <br /> {wl.workload_data.workload_approval_date ? formatDate(wl.workload_data.workload_approval_date) : 'N/A'}</Body>
                  </Col>
                  <Col>
                    <Body baseFontSize={16} className={"py-1"}><b>Starting Run Rate</b> <br /><span className='ms-0'>USD {USDollar.format(wl.workload_data.workload_starting_arr)}</span></Body>
                    <Body baseFontSize={16} className={"py-1"}><b>Estimated Run Rate</b> <br /> USD {USDollar.format(wl.workload_data.workload_forecast_arr)}</Body>
                  </Col>
                  <Col>
                    <Body baseFontSize={16} className={"py-1"}><b>Current Run Rate</b> <br /> USD {USDollar.format(parseFloat(String(wl.workload_data.workload_actual_arr)) + parseFloat(String(wl.workload_data.workload_starting_arr)))}</Body>
                    <Body baseFontSize={16} className={"py-1"}><b>Incremental Run Rate</b> <br /> USD {USDollar.format(wl.workload_data.workload_actual_arr)}</Body>                              
                  </Col>
                  <Col>
                    <Body baseFontSize={16} className={"py-1"}><b>Days in Current Stage</b> <br /><span className='ms-0'>{wl?.workload_data?.workload_days_in_stage ? wl?.workload_data?.workload_days_in_stage : "N/A"}</span></Body>
                    <Body baseFontSize={16} className={"py-1"}><b># Realm/Search Queries - Last 7 Days</b> <br /> {wl.workload_data.realm_search_queries_last_7_days__c}</Body>
                  </Col>
                  
                </Row>
                <hr />
                <Row>
                    <Col>
                      <Body baseFontSize={16} className={"py-1"}>
                        <b>Three Whys:</b>
                        <br />
                        {wl.workload_data.x3_whys_c}
                      </Body>
                    </Col>
                    <Col>
                      <Body baseFontSize={16} className={"py-1"}>
                        <b>Customer MongoDB Knowledge:</b>
                        <br />
                        {wl.workload_data.customers_mongodb_knowleddge_c}
                      </Body>
                    </Col>
                </Row>
                <Row>
                <Col>
                      <Body baseFontSize={16} className={"py-1"}>
                        <b>Application Overview:</b>
                        <br />
                        {wl.workload_data.application_overview_c}
                      </Body>
                    </Col>
                    <Col>
                      <Body baseFontSize={16} className={"py-1"}>
                        <b>Atlas Pricing Architecture:</b>
                        <br />
                        {wl.workload_data.atlas_pricing_architectures_c}
                      </Body>
                    </Col>
                </Row>
                <Row>
                <Col>
                      <Body baseFontSize={16} className={"py-1"}>
                        <b>Business Outcomes:</b>
                        <br />
                        {wl.workload_data.business_outcomes_c}
                      </Body>
                    </Col>
                    <Col>
                      <Body baseFontSize={16} className={"py-1"}>
                        <b>Deal Type:</b>
                        <br />
                        {wl.workload_data.deal_type_c}
                      </Body>
                    </Col>
                    
                </Row>
                
                <Row>
                <Col>
                      <Body baseFontSize={16} className={"py-1"}>
                        <b>Onboarding Sub Status:</b>
                        <br />
                        {wl.workload_data.onboarding_sub_status_c}
                      </Body>
                    </Col>
                    <Col>
                      <Body baseFontSize={16} className={"py-1"}>
                        <b>Professional Services:</b>
                        <br />
                        {wl.workload_data.professionalservices_c}
                      </Body>
                    </Col>
                    <Col>
                      <Body baseFontSize={16} className={"py-1"}>
                        <b>PS Opportunity:</b>
                        <br />
                        {wl.workload_data.ps_opportunity_c}
                      </Body>
                    </Col>
                </Row>
                <Row>
                <Col>
                      <Body baseFontSize={16} className={"py-1"}>
                        <b>RVP Strategic Tagging:</b>
                        <br />
                        {wl.workload_data.rvp_strategic_tagging_c}
                      </Body>
                    </Col>
                    <Col>
                      <Body baseFontSize={16} className={"py-1"}>
                        <b>App Architecture Notes:</b>
                        <br />
                        {wl.workload_data.app_architecture_notes_c}
                      </Body>
                    </Col>
                    <Col>
                      <Body baseFontSize={16} className={"py-1"}>
                        <b>Success Metrics:</b>
                        <br />
                        {wl.workload_data.success_metrics_c}
                      </Body>
                    </Col>
                </Row>
                <Row>
                <Col>
                      <Body baseFontSize={16} className={"py-1"}>
                        <b>Value Drivers:</b>
                        <br />
                        {wl.workload_data.value_drivers_c}
                      </Body>
                    </Col>
                    <Col>
                      <Body baseFontSize={16} className={"py-1"}>
                        <b>Go-Live Production Date:</b>
                        <br />
                        {wl.workload_data.go_live_production_date_c?.toString()}
                      </Body>
                    </Col>
                    <Col>
                      <Body baseFontSize={16} className={"py-1"}>
                        <b>Use Case:</b>
                        <br />
                        {wl.workload_data.use_case_c}
                      </Body>
                    </Col>
                </Row>

                <Row>
                <Col>
                      <Body baseFontSize={16} className={"py-1"}>
                        <b>CSM Comments:</b>
                        <br />
                        {wl.workload_data.csm_comments_c}
                      </Body>
                    </Col>
                    <Col>
                      <Body baseFontSize={16} className={"py-1"}>
                        <b>General Comments:</b>
                        <br />
                        {wl.workload_data.comments_c}
                      </Body>
                    </Col>
                </Row>
                
              </Card>
                
              </Row>


          </Tab>
          <Tab name="CTAs"
            onClick={()=>{
              setTabIndex(3);
            }}>
            <Row className={" justify-content-around"}>
              <Card style={{width:"95%"}}>
                {(snoozeInfo?.snoozed == true) &&
                  <div>
                    <H2>CTAs Snoozed</H2>
                    <Body><b>Reason: </b>{snoozeInfo.reason}</Body>
                    <Body><b>Snoozed At: </b>{snoozeInfo.timeUpdated.toString()}</Body>
                    <Body><b>Snooze Expires At: </b>{snoozeInfo.expireAt.toString()}</Body>
                    <Body><b>Comments: </b>{snoozeInfo.comments}</Body>
                    <hr />
                  </div>
                }
                {wl?.workload_data?.gs_cta_id ? <><H2>
                  CTAs
                  {wl?.workload_data?.snoozed &&
                  <Button style={{width:"10em","float":"right",marginRight:"5em"}} onClick={()=>{
                    setSnooze(wl?.workload_data?.workload_id,false, snoozeComments, snoozeWhy, snoozeExp).then(()=>{
                      window.location.reload();
                    });
                  }}> Unsnooze 💤 </Button>
                  }
                  {!wl?.workload_data?.snoozed &&
                  <Button style={{width:"10em","float":"right",marginRight:"5em"}} onClick={()=>{
                    setSnoozeModalShow(true);
                  }}> Snooze 💤 </Button>
                  }
                </H2>
                
                <Table
                  data={[wl.workload_data]}
                  columns={[
                    <TableHeader label="Open Date" sortBy={(datum: any) => (datum.gs_cta_open_date)} />,
                    <TableHeader label="Name" sortBy={(datum: any) => datum.gs_cta_name} />,
                    <TableHeader label="Status" sortBy={(datum: any) => datum.gs_cta_status} />,
                    <TableHeader label="Indicator" sortBy={(datum: any) => datum.gs_cta_indicator} />,
                    <TableHeader label="Comments" sortBy={(datum: any) => datum.gs_cta_additional_comments_validity} />,
                    <TableHeader label="Url" sortBy={(datum: any) => datum.gs_cta_url_link} />,
                  ]}
                >
                  {({ datum }: any) => (
                    <TRow key={datum.gs_cta_id}>
                      <Cell>{(new Date(datum.gs_cta_open_date).toISOString().split('T')[0])}</Cell>
                      <Cell>{datum.gs_cta_name}</Cell>
                      <Cell>{datum.gs_cta_status}</Cell>
                      <Cell>{datum.gs_cta_indicator}</Cell>
                      <Cell><span dangerouslySetInnerHTML={{__html:extractContent(datum.gs_cta_additional_comments_validity)?extractContent(datum.gs_cta_additional_comments_validity):"None"}}></span></Cell>
                      <Cell><Link href={datum.gs_cta_url_link}>Link to CTA</Link></Cell>
                    </TRow>
                  )}
                </Table> </> : <div>
                  {wl?.workload_data?.snoozed &&
                  <Button style={{width:"10em","float":"right",marginRight:"5em"}} onClick={()=>{
                    setSnooze(wl?.workload_data?.workload_id,false, snoozeComments, snoozeWhy, snoozeExp).then(()=>{
                      window.location.reload();
                    });
                  }}> Unsnooze 💤 </Button>
                  }
                  {!wl?.workload_data?.snoozed &&
                  <Button style={{width:"10em","float":"right",marginRight:"5em"}} onClick={()=>{
                    setSnoozeModalShow(true);
                  }}> Snooze 💤 </Button>
                  }
                  <H2>No CTAs</H2>
                  </div>}

                </Card>
                </Row>
          </Tab>
        </Tabs>
      </Row>
      
      </>
        }
      </div>
   );
}

export default WLV;
