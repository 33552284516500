import React from "react";
import "./App.css";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'material-icons/iconfont/material-icons.css';

import { Switch, Route } from "react-router-dom";
import { useHistory } from "react-router-dom";

import Navigation from "./components/Navigation";
import Home from "./Pages/Home";
import Feed from "./Pages/Feed";
import Ctas from "./Pages/Ctas";
import About from "./Pages/About";
import WorkloadView from "./Pages/WLV";
import WLVDEBUG from "./Pages/WLVDEBUG";
import {Login} from "./Pages/Login";

import { useGlobal } from "./hooks/useGlobal";

import ProtectedRoute from "./components/ProtectedRoute";

import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Config } from './utils/config';
import { LoginCallback, Security, useOktaAuth } from '@okta/okta-react';
import Loading from "./Pages/Loading";
import { AnalyticsProvider, useAnalytics } from 'use-analytics';
import analytics from './analytics';

const oktaAuth = new OktaAuth({
   issuer: Config.okta.issuer,
   clientId: Config.okta.clientId,
   redirectUri: window.location.origin + '/login/callback',
   scopes: ['openid', 'profile', 'email'],
   pkce: true
});
 
function App() {
   const navigate = useHistory();
   const {loading,setLoading} = useGlobal();
   setLoading(false);
   const restoreOriginalUri = (_oktaAuth: any, originalUri: string) => {
      navigate.push(toRelativeUrl( '/', window.location.origin));
   };
   
   return (
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
         <AnalyticsProvider instance={analytics}>
            <div className="App">
               <Navigation />

               {loading ? (
                  <div style={{
                     width: "100%", height: "100vh", color: "black", paddingTop: "25em"
                  }}> Loading... </div>
               ) : (
                  <>
                     
                     <Switch>
                        <Route path="/" exact component={Login} />
                        <Route path="/info" exact component={Home} />
                        <ProtectedRoute path="/workloads" exact component={Feed} />
                        <Route path="/workload/:wl_id" exact component={WorkloadView} />
                        <Route path="/workload_debug/:wl_id" exact component={WLVDEBUG} />
                        <Route path="/ctas" exact component={Ctas} />
                        <Route path="/team" exact component={About} />   
                        <Route path="/login/callback" component={LoginCallback}/>
                        <Route path="/login" exact component={Login} />
                     </Switch>
                  </>
               )}
            </div>
         </AnalyticsProvider>
      </Security>
   );
}

export default App;
