import React, { useState, createContext, useContext, useEffect } from "react";

// Create the context
const GlobalContext = createContext(null);

export const GlobalProvider = ({ children }) => {
   const [loading, setLoading] = useState<boolean>(true);
   const [activeView,setActiveView] = useState("");

   const [filter_csm,setFilterCSM] = useState<string>("");
   const [filter_owner,setFilterOwner] = useState<string>("");
   const [filter_account,setFilterAccount] = useState<string>("");

   const [filter_min_arr,setMinARR] = useState(null);
   const [filter_max_arr,setMaxARR] = useState(null)
   
   const [globalWorkloads,setGlobalWorkloads] = useState<any[]>([]);

   const filterApply = (x:any)=>{
      //console apply the below filters to the globalWorkloads
      console.log('globalFilter',x);
   };
   useEffect(() => {
      setLoading(false);
   }, []);


   return (
      // Using the provider so that ANY component in our application can
      // use the values that we are sending.
      <GlobalContext.Provider
         value={{ activeView,setLoading, setActiveView, filter_min_arr, filter_max_arr, setMinARR, setMaxARR, loading ,filter_csm,setFilterCSM, filter_owner,setFilterOwner, filter_account,setFilterAccount,globalWorkloads,setGlobalWorkloads, filterApply}}
      >
         {children}
      </GlobalContext.Provider>
   );
};

export const useGlobal = () => useContext(GlobalContext);