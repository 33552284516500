import Icon from "@leafygreen-ui/icon";
import { Menu, MenuSeparator } from "@leafygreen-ui/menu";
import * as React from "react";
import Badge from '@leafygreen-ui/badge';
import {useLocation,useSearchParams,useHistory} from "react-router-dom";

import { Col, Container, Row } from "react-bootstrap";
import DataTable from 'react-data-table-component';
import { Combobox, ComboboxOption } from '@leafygreen-ui/combobox';

import  Button  from '@leafygreen-ui/button';
import TextInput from '@leafygreen-ui/text-input';
import { Body, Link, InlineCode, Error, H3 } from '@leafygreen-ui/typography';
import IconButton from '@leafygreen-ui/icon-button';
import { useAuth } from "../hooks/useAuth";
import { useGlobal } from "../hooks/useGlobal";
import queryString from 'query-string';

function Filters() {
    const location = useLocation();
    const parsed = queryString.parse(location.search);
    const {user,authed,autocompleteAccountName,autocompleteOwnerName,autocompleteCsmName,getDetails} = useAuth();
    let USDollar = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    const {filter_csm,setFilterCSM,setMaxARR,setMinARR, loading,filter_owner,setFilterOwner, filter_account,setFilterAccount, filterApply, filter_min_arr, filter_max_arr,setLoading} = useGlobal();
    React.useEffect(()=>{
        if(filter_csm != ""){
            setFilterState({...filterState,csm_id:filter_csm})
        }
    },[filter_csm])
    React.useEffect(()=>{
        if(filter_account != ""){
            setFilterState({...filterState,account_id:filter_account})
        }
    },[filter_account])
    React.useEffect(()=>{
        if(filter_owner != ""){
            setFilterState({...filterState,owner_id:filter_owner})
        }
    },[filter_owner])

    React.useEffect(()=>{
        if(filter_max_arr != null){
            setFilterState({...filterState,max_arr:filter_max_arr})
        }
    },[filter_max_arr])

    React.useEffect(()=>{
        if(filter_min_arr != null){
            setFilterState({...filterState,min_arr:filter_min_arr})
        }
    },[filter_min_arr])
    const [autocompleteMatches, setAutocompleteMatches] = React.useState<any[]>([]);   
    const [ownerAutocompleteMatches, setOwnerAutocompleteMatches] = React.useState<any[]>([])
    const [csmAutocompleteMatches, setCsmAutocompleteMatches] = React.useState<any[]>([])

    const [cleanOwner,setCleanOwner] = React.useState("");
    const [cleanAccount,setCleanAccount] = React.useState("");
    const [cleanCSM,setCleanCSM] = React.useState("");
    
    const [filterMenuOpen,setFilterMenuOpen] = React.useState(false);
    const handleAutocomplete = async (e: any) => {
        if (e.target.value) {
          const response: any = await autocompleteAccountName(e.target.value)
          setAutocompleteMatches(response)
        }
        else {
          setAutocompleteMatches([]);
        }
    };
    const handleOwnerAutocomplete = async (e: any) => {
        if (e.target.value) {
          const response: any = await autocompleteOwnerName(e.target.value)
          setOwnerAutocompleteMatches(response)
        }
        else {
          setOwnerAutocompleteMatches([]);
        }
    };
    const handleCsmAutocomplete = async (e: any) => {
        if (e.target.value) {
            const response: any = await autocompleteCsmName(e.target.value)
            setCsmAutocompleteMatches(response)
        }
        else {
            setCsmAutocompleteMatches([]);
        }
    };
   const [filterState,setFilterState] = React.useState({
    csm_id:null,
    csm_name:null,
    owner_id:null,
    owner_name:null,
    account_id:null,
    account_name:null,
    min_arr:null,
    max_arr:null,
//    status:["Unhealthy","At Risk"]
   });

   const [viewState,setViewState] = React.useState({
    
   });

   const navigate = useHistory();
   React.useEffect(()=>{
        console.log('filterState Changed',filterState);
        //setFilterCSM
        //setFilterOwner
        //setFilterAccount 
        //update the clean stuff
        if(filterState.account_id){
          getDetails("account",filterState.account_id).then((x)=>{
            console.log('details results:',x)
            setCleanAccount(x.result.name);
          })
        }
        if(filterState.owner_id && parsed.owner_id){
          getDetails("owner",parsed.owner_id).then((x)=>{
            console.log('details results:',x.result.full_name)
            setCleanOwner(x.result.full_name);
          })
        }else if(filterState.owner_id){
          getDetails("owner",filterState.owner_id).then((x)=>{
            console.log('details results:',x.result.full_name)
            setCleanOwner(x.result.full_name);
          })
        }

        if(filterState.csm_id){
          getDetails("csm",filterState.csm_id).then((x)=>{
            console.log('details results:',x.result.full_name);
            setCleanCSM(x.result.full_name);
          })
        }
        //keep global filters in a state that matches this state
   },[filterState])

   const getRealName = async function(){
    //when filterState changes, get the "pretty names"
    let x = await getDetails("owner","0052K00000AYN9AQAV");
    return JSON.stringify(x);
   }

   return (
      <div id="filters-div" className="itemlist-section-filters" style={{marginTop:"-1.2em",zIndex:-1,paddingBottom:"1em"}}>
        {!loading && 
        <Row className="flex-nowrap" >
        <Col  >
          <Row xs={12} className={`pt-4`}>
            <Col className={`pt-2`}>
            <Button
                  aria-controls="filter-controls"
                  // aria-expanded={open}
                  aria-haspopup="true"
                  aria-label="Open Filter Controls"
                  title="Open Filter Controls"
                  onClick={()=>{setFilterMenuOpen(!filterMenuOpen)}}
                >
                  <Icon glyph="Filter" data-testid="glyph" /> 
                </Button>
              <Menu
                align="bottom"
                darkMode={false}
                justify="end"
                maxHeight={700}
                className='filter-container px-2'
                open={filterMenuOpen}
                spacing={6}
                usePortal={true}
                >
                <Row xs={2} className="my-2 mx-4 flex-nowrap" >
                  <Button 
                      className="mx-1" 
                      disabled={(!filterState.csm_id && !filterState.owner_id && !filterState.account_id) || ((filterState.max_arr!="" && filterState.min_arr!="") && parseInt(filterState.max_arr,10) < parseInt(filterState.min_arr,10))} 
                      onClick={() => {
                          console.log('APPLY FILTERS')
                          document.getElementById('filters-div').style.visibility = "hidden";
                          setLoading(true);
                          if(filterState.min_arr){
                              setMinARR(filterState.min_arr);
                          }
                          if(filterState.max_arr){
                              setMaxARR(filterState.max_arr);
                          }
                          if(filterState.csm_id){
                              setFilterCSM(filterState.csm_id);
                          }else if(filterState.account_id){
                              setFilterAccount(filterState.account_id);
                          }else if(filterState.owner_id){
                              setFilterOwner(filterState.owner_id);
                          }

                          if(filterState.account_id){
                              if(filterState.csm_id){
                                  if(filterState.max_arr && filterState.min_arr){
                                      //acct + csm
                                      window.location.href = "/workloads?account_id="+encodeURIComponent(filterState.account_id)+"&csm_id="+encodeURIComponent(filterState.csm_id)+"&min_arr="+encodeURIComponent(filterState.min_arr)+"&max_arr="+encodeURIComponent(filterState.max_arr);
                                  }else if(filterState.max_arr){
                                      //acct + csm
                                      window.location.href = "/workloads?account_id="+encodeURIComponent(filterState.account_id)+"&csm_id="+encodeURIComponent(filterState.csm_id)+"&max_arr="+encodeURIComponent(filterState.max_arr);
                                  }else if(filterState.min_arr){
                                      //acct + csm
                                      window.location.href = "/workloads?account_id="+encodeURIComponent(filterState.account_id)+"&csm_id="+encodeURIComponent(filterState.csm_id)+"&min_arr="+encodeURIComponent(filterState.min_arr);
                                  }else{
                                      //acct + csm
                                      window.location.href = "/workloads?account_id="+encodeURIComponent(filterState.account_id)+"&csm_id="+encodeURIComponent(filterState.csm_id);
                                  }
                              }else if(filterState.owner_id){
                                  if(filterState.max_arr && filterState.min_arr){
                                      //acct + owner
                                      window.location.href = "/workloads?account_id="+encodeURIComponent(filterState.account_id)+"&owner_id="+encodeURIComponent(filterState.owner_id)+"&min_arr="+encodeURIComponent(filterState.min_arr)+"&max_arr="+encodeURIComponent(filterState.max_arr);
                                  }else if(filter_max_arr){
                                      //acct + owner
                                      window.location.href = "/workloads?account_id="+encodeURIComponent(filterState.account_id)+"&owner_id="+encodeURIComponent(filterState.owner_id)+"&max_arr="+encodeURIComponent(filterState.max_arr);
                                  }else if(filterState.min_arr){
                                      //acct + owner
                                      window.location.href = "/workloads?account_id="+encodeURIComponent(filterState.account_id)+"&owner_id="+encodeURIComponent(filterState.owner_id)+"&min_arr="+encodeURIComponent(filterState.min_arr);
                                  }else{
                                      //acct + owner
                                      window.location.href = "/workloads?account_id="+encodeURIComponent(filterState.account_id)+"&owner_id="+encodeURIComponent(filterState.owner_id);
                                  }
                              }else{
                                  //just acct
                                  if(filterState.max_arr && filterState.min_arr){
                                      //acct + owner
                                      window.location.href = "/workloads?account_id="+encodeURIComponent(filterState.account_id)+"&min_arr="+encodeURIComponent(filterState.min_arr)+"&max_arr="+encodeURIComponent(filterState.max_arr);
                                  }else if(filterState.max_arr){
                                      //acct + owner
                                      window.location.href = "/workloads?account_id="+encodeURIComponent(filterState.account_id)+"&max_arr="+encodeURIComponent(filterState.max_arr);
                                  }else if(filterState.min_arr){
                                      //acct + owner
                                      window.location.href = "/workloads?account_id="+encodeURIComponent(filterState.account_id)+"&min_arr="+encodeURIComponent(filterState.min_arr);
                                  }else{
                                      //acct + owner
                                      window.location.href = "/workloads?account_id="+encodeURIComponent(filterState.account_id);
                                  }
                              }
                          }else if(filterState.csm_id){
                              if(filterState.max_arr && filterState.min_arr){
                                  window.location.href = "/workloads?csm_id="+encodeURIComponent(filterState.csm_id)+"&min_arr="+encodeURIComponent(filterState.min_arr)+"&max_arr="+encodeURIComponent(filterState.max_arr);
                              }else if(filterState.max_arr){
                                  window.location.href = "/workloads?csm_id="+encodeURIComponent(filterState.csm_id)+"&max_arr="+encodeURIComponent(filterState.max_arr);
                              }else if(filterState.min_arr){
                                  window.location.href = "/workloads?csm_id="+encodeURIComponent(filterState.csm_id)+"&min_arr="+encodeURIComponent(filterState.min_arr);
                              }else{
                                  window.location.href = "/workloads?csm_id="+encodeURIComponent(filterState.csm_id);
                              }
                          }else if(filterState.owner_id){
                              if(filterState.max_arr && filterState.min_arr){
                                  window.location.href = "/workloads?owner_id="+encodeURIComponent(filterState.owner_id)+"&min_arr="+encodeURIComponent(filterState.min_arr)+"&max_arr="+encodeURIComponent(filterState.max_arr);
                              }else if(filterState.max_arr){
                                  window.location.href = "/workloads?owner_id="+encodeURIComponent(filterState.owner_id)+"&max_arr="+encodeURIComponent(filterState.max_arr);
                              }else if(filterState.min_arr){
                                  window.location.href = "/workloads?owner_id="+encodeURIComponent(filterState.owner_id)+"&min_arr="+encodeURIComponent(filterState.min_arr);
                              }else{
                                  window.location.href = "/workloads?owner_id="+encodeURIComponent(filterState.owner_id);
                              }
                          }else{
                              alert('This should not happen.')
                          }
                          
                      }}
                    variant="primary"
                  >
                      Apply Filters</Button>
                  <Button className="mx-1" onClick={() => {
                    console.log('RESET FILTERS')

                  }}>Reset Filters</Button>
                </Row>
                <Row className="my-2 mx-4 flex-nowrap" >
                  {!filterState.owner_id && !filterState.csm_id && !filterState.account_id ? <><Error darkMode={true}>Specify at least one: Owner, CSM, or Account</Error></> : null}
                </Row>
                <Row
                  className='px-4 py-1'
                  onClick={() => { }}
                >
                  <Combobox
                    darkMode={true}
                    onFilter={(selection: any) => {
                      console.log('selection',selection);
                    }}
                    onClear={() => { setFilterState({ ...filterState, owner_id: null }) }}
                    onInput={handleOwnerAutocomplete}
                    onChange={(e)=>{
                      setFilterState({...filterState, owner_id:e, csm_id:null})
                    }}
                    label="Owner" description="" placeholder="Account Owner" multiselect={false} value={filterState.owner_id}>
                    {ownerAutocompleteMatches && ownerAutocompleteMatches.length ?
                      ownerAutocompleteMatches?.map((a) => {
                        return <ComboboxOption onClickCapture={() => {
                          console.log(`selected ${a.full_name} with ${a.user_id}`);
                          setFilterState({...filterState, owner_id:a.user_id, csm_id:null});
                        }} value={a?.user_id} displayName={a?.full_name ? a?.full_name : ''} />
                      }) : <ComboboxOption value={filterState.owner_id} displayName={filterState.owner_id} />}
                  </Combobox>
                </Row>
                <Row className='px-4 py-1'>
                  <Combobox
                    darkMode={true}
                    onClear={() => { setFilterState({ ...filterState, csm_id: null }) }}
                    onInput={handleCsmAutocomplete}
                    onChange={(e)=>{
                      setFilterState({...filterState, csm_id:e, owner_id:null})
                    }}
                    label="CSM" description="" placeholder="CSM" multiselect={false} value={filterState.csm_id}>
                    {csmAutocompleteMatches && csmAutocompleteMatches.length ?
                      csmAutocompleteMatches?.map((a) => {
                        return <ComboboxOption onClickCapture={() => {
                          console.log(`selected ${a.full_name} with ${a.user_id}`);
                          setFilterState({...filterState, csm_id:a.user_id, owner_id:null});
                        }} value={a?.user_id} displayName={a?.full_name ? a?.full_name : ''} />
                      }) : <ComboboxOption value={filterState.csm_id} displayName={filterState.csm_id} />}
                    
                  </Combobox>
                </Row>
                
                <Row
                  className='px-4 py-1'
                  onClick={() => { }}
                >
                  <Combobox
                    darkMode={true}
                    onFilter={(selection: any) => {
                      console.log('selection',selection);
                    }}
                    onClear={() => { setFilterState({ ...filterState, account_id: null }) }}
                    onInput={handleAutocomplete}
                    onChange={(e)=>{
                      setFilterState({...filterState, account_id:e })
                    }}
                    label="Account" description="" placeholder="Account" multiselect={false} value={filterState.account_id}>
                    {autocompleteMatches && autocompleteMatches.length ?
                      autocompleteMatches?.map((a) => {
                        return <ComboboxOption onClickCapture={() => {
                          console.log(`selected ${a.id}`);
                          setFilterState({...filterState, account_name:a?.name, account_id:a.id});
                        }} value={a?.id} displayName={a?.name ? a?.name : ''} />
                      }) : <ComboboxOption value={filterState.account_id} displayName={filterState.account_id} />}
                  </Combobox>
                </Row>
                <Row className='px-4 py-1'><MenuSeparator /></Row>
                <Row className='px-4 py-1'>
                  <Row xs={2}>
                    <TextInput darkMode={true}
                      type="number"
                      width="10"
                      aria-labelledby='min-arr-search'
                      label="Min ARR"
                      placeholder="Min ARR"
                      onChange={(e: any) => {
                          setFilterState({ ...filterState, min_arr: String(e.target.value) })
                      }}
                      value={String(filterState.min_arr)}
                    />
                    <TextInput darkMode={true}
                      type="number"
                      aria-labelledby='max-arr-search'
                      label=" Max ARR"
                      placeholder="Max ARR"
                      onChange={(e: any) => {
                          setFilterState({ ...filterState, max_arr: String(e.target.value) })
                      }}
                      value={String(filterState.max_arr)}
                    />
                  </Row>


                </Row>

              </Menu>
            </Col>
            <Col className={`pt-1`} xs={11}>
              {filter_owner && !filterMenuOpen ?
                <Badge className='p-3 mx-2'>
                  Owner: {cleanOwner}
                  {
                    filter_owner ?
                      <IconButton aria-label={`Remove owner`} onClick={() => {
                          console.log('REMOVE owner')
                          setFilterMenuOpen(true);
                          //setFilterState({...filterState,owner_id:null})
                      }}><Icon glyph="X" /></IconButton>
                      : null
                  }
                </Badge>
                : null}
              {filter_csm && !filterMenuOpen ?
                <Badge  className='p-3 mx-2'>
                  CSM: {cleanCSM}
                  {
                    filter_csm ?
                      <IconButton aria-label={`Remove CSM`} onClick={() => {
                          console.log('REMOVE csm');
                          //setFilterState({...filterState,csm_id:null})
                          setFilterMenuOpen(true);
                      }}><Icon glyph="X" /></IconButton>
                      : null
                  }
                </Badge>

                : null}
              {filter_account && !filterMenuOpen ?
                <Badge  className='p-3 mx-2'>
                  Account: {cleanAccount}
                  {
                     filter_account ?
                      <IconButton aria-label={`Remove account`} onClick={() => {
                          console.log('REMOVE account')
                          //setFilterState({...filterState,account_id:null})
                          setFilterMenuOpen(true);
                      }}><Icon glyph="X" /></IconButton>
                      : null
                  }
                </Badge>
                : null}
              
              {filter_min_arr && !filterMenuOpen  && parseInt(filter_min_arr,10) > 0?
                <Badge className='p-3 mx-2'>
                  Min ARR: {USDollar.format(filterState.min_arr)}
                  <IconButton aria-label={`Remove minARR`} onClick={() => {
                    console.log('REMOVE minARR')
                      //setFilterState({...filterState,min_arr:"0"})
                          setFilterMenuOpen(true);
                  }}><Icon glyph="X" /></IconButton>
                </Badge>
                : null}
              {filter_max_arr && !filterMenuOpen && parseInt(filter_max_arr,10) > 0?
                <Badge className='p-3 mx-2'>
                  Max ARR: {USDollar.format(filterState.max_arr)}
                  <IconButton aria-label={`Remove maxARR`} onClick={() => {
                    console.log('REMOVE maxARR')
                    //setFilterState({...filterState,max_arr:null})
                    setFilterMenuOpen(true);
                  }}><Icon glyph="X" /></IconButton>
                </Badge>

                : null}

            </Col>
            

          </Row>

        </Col>
      </Row>
        }
      </div>
   );
}

export default Filters;







