import Analytics from 'analytics'
import * as Realm from "realm-web";

const realmApp = new Realm.App({ id: "web-tracking-pvfyu" });
let user:Realm.User;

const myPlugin = {
  initialize: async ( config:any ) => {
    let credentials = Realm.Credentials.apiKey("piRk834f0Sh773gCCSd5rK2eNnAhvJlVSlFit9mvz7fUGkoZjITMBWuBlbRio2Dx");//WLH API KEY
    user = await realmApp.logIn(credentials);
  },
  name: 'my-custom-plugin',
  page: async ( payload:any ) => {
    let credentials = Realm.Credentials.apiKey("piRk834f0Sh773gCCSd5rK2eNnAhvJlVSlFit9mvz7fUGkoZjITMBWuBlbRio2Dx");//WLH API KEY
    user = await realmApp.logIn(credentials);
    console.log('page view fired', payload);
    payload.payload.app_id = "WLH-HEALTH";
    await user.functions.track(payload.payload);
  },
  track: async ( payload:any ) => {
    let credentials = Realm.Credentials.apiKey("piRk834f0Sh773gCCSd5rK2eNnAhvJlVSlFit9mvz7fUGkoZjITMBWuBlbRio2Dx");//WLH API KEY
    user = await realmApp.logIn(credentials);
    console.log('track event payload', payload);
    payload.payload.app_id = "WLH-HEALTH";
    await user.functions.track(payload.payload);
  }
}

const analyticsInstance = Analytics({
  app: 'my-app',
  plugins: [
    myPlugin
  ]
})

export default analyticsInstance