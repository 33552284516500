
import { SyntheticEvent, useEffect, useState } from 'react';
import {
  Button,
  Col, Container, Form, Row,
} from 'react-bootstrap';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { Config } from '../utils/config';
import { useOktaAuth } from '@okta/okta-react';
import { useCookies } from 'react-cookie';
import { Body } from '@leafygreen-ui/typography';
import { MongoDBLogoMark } from '@leafygreen-ui/logo';

interface Props {
  loading?: boolean
}

export const Login = ({ loading }: Props) => {
  const [credentials, setCredentials] = useState({ username: '', password: '' })

  const navigate = useHistory();
  const [submitting, setSubmitting] = useState(false)
  const { oktaAuth, authState } = useOktaAuth();
  const [cookies, setCookie] = useCookies([Config.AUTH_COOKIE]);
  const location = useLocation();
  /**
   * When the authentication state changes, check if user needs to be logged in or rehydrated from auth cookie
   */
  useEffect(() => {
    if (authState?.isAuthenticated) {
      navigate.push("/workloads"+location.search);
    }
  }, [authState]);



  // SSOProvider object to load scripts and render button
  const SSOProvider = {
    render: () => {
        if (!authState?.isAuthenticated) {
            return (
          <div>
            <Button variant="success" type="submit" onClick={() => oktaAuth.signInWithRedirect()} disabled={submitting} >
              Login with Okta SSO
            </Button>
          </div>
        );
      }

      return (
        navigate.push("/login"+location.search)
      );
    }
  }

  /**
   * render Login component
   */
  return (
    <div className="bg-mongodb align-items-center pt-5">
      <Container className="bg-white login-container pt-5 d-flex flex-row text-center ">
        <Col className="p-5">
          <MongoDBLogoMark height={40} />
          <Body className="login-title" baseFontSize={16} weight="medium">Workload Health</Body>
          <Row className="my-4 py-4">
            <div >
              {SSOProvider.render()}
            </div>
          </Row>
        </Col>

      </Container>
    </div>
  )
}

