import React, { useState, createContext, useContext, useEffect } from "react";
import * as Realm from "realm-web";
import { Config } from '../utils/config';
import queryString from 'query-string';
import {useLocation,useSearchParams,useHistory} from "react-router-dom";

// Create the context
const AuthContext = createContext(null);

//const app = Realm.getApp(String(Config.realmAppId));
const app = Realm.getApp(String("web-health-dashboard-uosek"));
export const AuthProvider = ({ children }) => {
   const [authed, setAuthed] = useState<boolean>(app.currentUser?true:false);
   const [loading, setLoading] = useState<boolean>(true);
   const [mdbTeam,setMDBTeam] = useState("");
   const [user,setUser] = useState("");
   const [userIsMgr,setUserIsMgr] = useState(false);
   const [userDisplayName,setDisplayName] = useState("");
   const [UR,setUR] = useState([]); //user reports. bad variable name, my bad.
   const [userObject,setUserObject] = useState({});
   const location = useLocation();
   const parsed = queryString.parse(location.search);

   async function loginEmailPassword(email:string, password:string) {
      // Create an e-mail/pwd credential
      const credentials = Realm.Credentials.emailPassword(email, password);
      try {
         // Authenticate the user
         const user = await app.logIn(credentials);
         return user
      } catch(err) {
         console.error("Failed to log in", err);
      }
   }
   const loginCustomJwt = async function (jwt) {
    // Create a Custom JWT credential
    const credentials = Realm.Credentials.jwt(jwt);
    // Authenticate the user
    const user = await app.logIn(credentials);
    // `App.currentUser` updates to match the logged in user
    console.assert(user.id === app.currentUser.id);
    return user;
  }
   const tryToLogin = async function(jwtStr:string){
      try{
        const realmUser = await loginCustomJwt(jwtStr);
        return realmUser;
      }catch(e){
        console.log('e-login',e);
        return false;
      }
    };
   useEffect(() => {
      setLoading(false);
   }, []);

   const login = async (jwtStr:string): Promise<void> => {
      const result = await tryToLogin(jwtStr);
      if (result) {
         console.log("user has logged in",result,result['_profile']['data']['email']);
         sessionStorage.setItem('og_user',result['_profile']['data']['email']);
         //is there a param override?
         setUser(parsed?.view_as ? parsed?.view_as : result['_profile']['data']['email']);
         let ru = await getUser(parsed?.view_as ? parsed?.view_as : result['_profile']['data']['email']);
         console.log('ru',ru);
         if(!ru){
            ru = await getUser("britt.smith@mongodb.com");
         }
         setUserObject(ru);
         setDisplayName(ru.full_name);
         let userReports = await getReports(String(ru.user_id));// getReports('0056R00000CEIq7QAH');//ru['user_id']);
         console.log('reports',userReports);
         if(userReports.length > 0){
            //manager view
            setUR(userReports);
         }
         setMDBTeam(String(ru.mongodb_team).toLocaleLowerCase());
         setUserIsMgr((ru.is_manager === true)?true:false);
         setAuthed(true);
         sessionStorage.setItem("_token", String(result['_accessToken']));
      }
   };

   const logout = async (): Promise<void> => {
      setAuthed(false);
      console.log("The User has logged out");
      sessionStorage.setItem("_token", "");
   };

   const getUserWorkloads = async(query:any): Promise<any> => {
    const response: any = await app.currentUser?.callFunction("findUserWorkloads", query)
    return response;
   };
   const getUser = async(query:string): Promise<any> => {
      const response: any = await app.currentUser?.callFunction("findUserByEmail", query)
      return response;
     };
   const getReports = async(query:string): Promise<any> => {
      const response: any = await app.currentUser?.callFunction("getReports", query)
      return response;
   };
   const getWorkload = async(id:string): Promise<any> => {
      const response: any = await app.currentUser?.callFunction("getWorkload", id)
      return response;
   };
   const setSnooze = async(wlId:string,snoozeStatus:boolean,comments:string,r:string,d:string): Promise<any> => {
      console.log('wlId',wlId,snoozeStatus);
      const response: any = await app.currentUser?.callFunction("setSnooze", wlId,snoozeStatus,comments,r,d)
      return response;
   };
   const getDetails = async(assetType:string, assetId:string): Promise<any> => {
      console.log('getDetails',assetType,assetId);
      const response: any = await app.currentUser?.callFunction("getDetails", assetType, assetId)

      return response;
   };
   const getWorkloadsCtas = async (filters: any): Promise<any> => {
      let query: any = { }
      //query = getQueryWithFilters(query, filters)
      const response: any = await app.currentUser?.callFunction("getWorkloadsCtas", filters)
      console.log(`getWorkloadsCtas response`, response);
      return response;
   };

   const register = async (registerEmail:string,registerPassword:string): Promise<void> => {
      setAuthed(false);
      await app.emailPasswordAuth.registerUser({email:registerEmail,"password":registerPassword});
      alert("Successfully registered!");
   };

   const autocompleteOwnerName = async (query: string): Promise<string> => {
      const response: any = await app.currentUser?.callFunction("autocompleteOwnerName", { ownerName: query })
      // // console.log(`AUTOCOMPLETE`, response);
      return response;
    };
    const autocompleteWL = async (query: string): Promise<string> => {
      const response: any = await app.currentUser?.callFunction("autocompleteWLName", { wlName: query })
      console.log(`AUTOCOMPLETE`, response);
      return response;
    };
    
   const autocompleteCsmName = async (query: string): Promise<string> => {
      const response: any = await app.currentUser?.callFunction("autocompleteCsmName", { csmName: query })
      // console.log(`AUTOCOMPLETE`, response);
      return response;
    };
    const autocompleteSales = async (query: string): Promise<string> => {
      const response: any = await app.currentUser?.callFunction("autocompleteSales", { csmName: query })
      // console.log(`AUTOCOMPLETE`, response);
      return response;
    };
   const autocompleteAccountName = async (query: string): Promise<string> => {
      const response: any = await app.currentUser?.callFunction("autocompleteAccountName", { accountName: query })
      console.log(`AUTOCOMPLETE`, response);
      return response;
    };


    const getTeamTotals = async (query: any): Promise<any> => {
      const response: any = await app.currentUser?.callFunction("getTeamTotals", query)
      console.log(`response`, response);
      return response;
    };

   return (
      // Using the provider so that ANY component in our application can
      // use the values that we are sending.
      <AuthContext.Provider
         value={{ autocompleteSales,autocompleteWL,userIsMgr, userObject, getDetails, authed, getTeamTotals, UR, autocompleteAccountName, autocompleteCsmName, autocompleteOwnerName, setAuthed, login, logout, loading, register, getUserWorkloads,getWorkload,getUser,user,setUser,setSnooze,getWorkloadsCtas }}
      >
         {children}
      </AuthContext.Provider>
   );
};

export const useAuth = () => useContext(AuthContext);