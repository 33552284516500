import React, { useEffect } from "react";
import {useState} from "react";

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { SearchInput } from "@leafygreen-ui/search-input";
import Icon from "@leafygreen-ui/icon";
import Badge from "@leafygreen-ui/badge";
import { useOktaAuth } from '@okta/okta-react';

import { useGlobal } from "../hooks/useGlobal";
import { useAuth } from "../hooks/useAuth";
import { Combobox, ComboboxOption } from "@leafygreen-ui/combobox";
import {useLocation,useSearchParams,useHistory} from "react-router-dom";
import queryString from 'query-string';
import Modal from "@leafygreen-ui/modal";
import { Body } from "@leafygreen-ui/typography";
import { Row } from "react-bootstrap";

function Navigation() {

  const navigate = useHistory();

  const [wlNameFilter,setWLNameFilter] = useState("");
  const { oktaAuth, authState } = useOktaAuth();
  const {activeView} = useGlobal();
  const {login,logout,getWorkloadsCtas,user,getUser,autocompleteWL,userObject,userIsMgr} = useAuth();
  const [ctaCount,setCtaCount] = useState(0);
  const [wlAutoComplete,setWLAutocompleteMatches] = useState([]);
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const [csmAutocompleteMatches, setCsmAutocompleteMatches] = React.useState<any[]>([])
  const {authed,autocompleteAccountName,autocompleteOwnerName,autocompleteCsmName,autocompleteSales,getDetails} = useAuth();

  const handleCsmAutocomplete = async (e: any) => {
      if (e.target.value) {
          const response: any = await autocompleteSales(e.target.value)
          setCsmAutocompleteMatches(response)
      }
      else {
          setCsmAutocompleteMatches([]);
      }
  };
  const handleWLAutocomplete = async (e: any) => {
    if (e.target.value) {
      const response: any = await autocompleteWL(e.target.value)
      console.log('resp',response);
      setWLAutocompleteMatches(response)
    }
    else {
      setWLAutocompleteMatches([]);
    }
};
  const userLogout = async function(){
    if (authState?.isAuthenticated) {
      oktaAuth.signOut();
    }
  };

  useEffect(()=>{
    console.log('userObject',userObject);
    if(userObject.user_id){
      let filters = {
        "account_id": null,
        "csm_id": [userObject.user_id],
        "owner_id": null,
      };
      getWorkloadsCtas(filters).catch((e)=>{
        console.log('err',e);
      }).then((x)=>{
        console.log('UPDATE CTAS @ NAVIGATION',x.length);
        setCtaCount(x.length);
      });
      
    }
  },[userObject])

  useEffect(()=>{
    if (authState?.isAuthenticated) {
      login(authState.idToken.idToken);
    }
    
  },[authState])

  const [viewAsModalShow,setViewAsModalShow] = useState(false);
  const [viewAs,setViewAs] = useState("");

   return (
      <Navbar className="border" expand="lg" fixed="top"  style={{background:"white"}}>
      <Container>
      <Modal open={viewAsModalShow} initialFocus="">
        <div>
          <Body>
            <h3><b>View As...</b></h3>
            <hr />
            <Row className='px-4 py-1'>
              <Combobox
                darkMode={true}
                onClear={() => { setViewAs("") }}
                onInput={handleCsmAutocomplete}
                onChange={(e)=>{
                  setViewAs(e);
                  window.location.href = "/?view_as="+encodeURIComponent(String(e))
                }}
                label="" description="" placeholder="CSM" multiselect={false} value={viewAs}>
                {csmAutocompleteMatches && csmAutocompleteMatches.length ?
                  csmAutocompleteMatches?.map((a) => {
                    return <ComboboxOption onClickCapture={() => {
                      console.log(`selected ${a.full_name} with ${a.email}`);
                    }} value={a?.email} displayName={a?.full_name ? a?.full_name : ''} />
                  }) : <ComboboxOption value={viewAs} displayName={viewAs} />}
                
              </Combobox>
            </Row>
                  <hr />
            <Button variant="danger" style={{float:"right"}} onClick={()=>{
                setViewAsModalShow(false);
              }}>Cancel</Button>
          </Body>
        </div>
      </Modal>
        <Navbar.Brand href={"/"}>

        <h1 style={{
          fontFamily: "'Darumadrop One', cursive"
          }}>
            <img src="/Group505.svg" />
          </h1>

        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="navbarScroll">
        {authState?.isAuthenticated && 
            <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
            >
              <Nav.Link className={(activeView==="")?"active":""} href={parsed?.view_as?"/workloads?view_as="+parsed?.view_as:"/workloads"}>My Workloads</Nav.Link>
              {userIsMgr &&
                <Nav.Link href={parsed?.view_as?"/team?view_as="+parsed?.view_as:"/team"}>Team</Nav.Link>  
              }
              {true &&
                <Nav.Link onClick={()=>{setViewAsModalShow(!viewAsModalShow)}}>View As...</Nav.Link>
              }
              <Nav.Link onClick={()=> userLogout()}>Logout</Nav.Link>  
            </Nav>
        }
        {!authState?.isAuthenticated && 

            <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
            >
              
              
            </Nav>

          }

          {authState?.isAuthenticated && 
            <Form className="d-flex">   
            <Nav>
              <Nav.Link href={parsed?.view_as?"/ctas?view_as="+parsed?.view_as:"/ctas"}>CTAs 
                <Badge>
                <Icon glyph="Bell"></Icon><span>{ctaCount}</span>
                </Badge>
              </Nav.Link>  
            </Nav>
                <Combobox
                    darkMode={false}
                    onFilter={(selection: any) => {
                      console.log('selection',selection);
                    }}
                    onClear={() => { }}
                    onInput={handleWLAutocomplete}
                    label="Search Workloads" description="" placeholder="Search for Workloads" multiselect={false} value={wlNameFilter}>
                    {wlAutoComplete && wlAutoComplete.length ?
                      wlAutoComplete?.map((a) => {
                        return <ComboboxOption onClickCapture={() => {
                          console.log(`selected ${a.name} with ${a.id}`);

                          navigate.push("/workload/"+a.id);
                        }} value={a?.id} displayName={a?.name ? a?.name : ''} />
                      }) : <ComboboxOption value={wlNameFilter} displayName={wlNameFilter} />}
                  </Combobox>


            </Form>

          }

          {!authState?.isAuthenticated && 
              <Form className="d-flex">   
              <Nav>
              </Nav>  
              </Form>
          }
          
        </Navbar.Collapse>
      </Container>
    </Navbar>
   );
}

export default Navigation;
