import React, { useEffect, useState } from "react";
import { Accordion, Col, Row } from 'react-bootstrap';
import { Form, ListGroup } from 'react-bootstrap'
import { useGlobal } from "../hooks/useGlobal";

import Card from '@leafygreen-ui/card';
import { Table, TableHeader, Row as TRow, Cell } from '@leafygreen-ui/table';
import { Body, H3, Link } from '@leafygreen-ui/typography';
import Badge from '@leafygreen-ui/badge';
import { extractIndicators, getLabelFromColor } from '../models/utils';
import ExpandableCard from '@leafygreen-ui/expandable-card';
import Button from '@leafygreen-ui/button';
import {SegmentedControl,SegmentedControlOption} from '@leafygreen-ui/segmented-control';
import {useLocation,useSearchParams,useHistory} from "react-router-dom";
import queryString from 'query-string';
import { useOktaAuth } from '@okta/okta-react';
import { MenuItem,Menu, MenuSeparator, SubMenu } from "@leafygreen-ui/menu";
import { useAuth } from "../hooks/useAuth";
import Icon from "@leafygreen-ui/icon";
import Modal from "@leafygreen-ui/modal";
import Filters from "./Filters";
import { useAnalytics } from "use-analytics";
import { start } from "repl";

let USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

interface Props {
  label: string;
  arr?: number;
  numRecords?: number;
  status?: any;
  showIndicators?: boolean
  handleWorkloadSelect: (workloadName: string, accountId: string, accountName: string) => void;
  handleAccountSelect: (accountId: string, accountName: string) => void;
  handleOwnerSelect?: (ownerId: string) => void;
}

export const WorkloadsTable = ({ label, numRecords, arr, status, showIndicators, handleWorkloadSelect, handleAccountSelect }: Props) => {
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const { authState } = useOktaAuth();
  const [segmentedCtrlVal, setSegmentedCtrlVal] = useState("");
  const navigate = useHistory();
  const [wl,setWl] = useState({workload_id:""});
  const {user,authed,getUserWorkloads,getUser} = useAuth();
  const [f,setF] = useState({account_id:null,owner_id:null,csm_id:null});
  const [data,setData] = useState([]);
  const {setMinARR,setMaxARR,filter_min_arr,filter_max_arr,filter_csm,setFilterCSM, loading,filter_owner,setFilterOwner, filter_account,setFilterAccount, filterApply,setGlobalWorkloads,globalWorkloads} = useGlobal();
  const analytics = useAnalytics();


  const [autocompleteMatches, setAutocompleteMatches] = useState([])
  const [query, setQuery] = useState('')

 

  const handleSearch = async (_query?: string) => {
    if (_query) {
      console.log('query',_query);
    }
  };


  const onAutocompleteSelect = (match: any) => {
    console.log(`autocomplete match`, match);
    setQuery(match)
    setAutocompleteMatches([])
  }

  useEffect(()=>{
    if(parsed && authState.isAuthenticated && authed){
      //logged in + query params

      //handle csm_id, account_id, owner_id
      let uf = {
        filters:{
          "account_id": null,
          "reports": null,
          "csm_id": [],
          "owner_id": null,
          "min_arr": 0,
          "max_arr": -1,
          "csm_name": null,
          "is_csm": true,
      }
      };
      console.log('parsed',parsed);
      //populate from state
      console.log('user',user);
      if(user){
        getUser(user).then((userDetails)=>{
          console.log('userDetails',userDetails);
          if(!userDetails){
            userDetails = {
              "mongodb_team":"CSM",
              "user_id":"0052K00000BcdhAQAR"
            }
          }
          if(userDetails.mongodb_team == "CSM"){
            uf['filters']['csm_id'] = userDetails.user_id;
            setFilterCSM(userDetails.user_id);
          }else{
            uf['filters']['owner_id'] = userDetails.user_id;
            setFilterOwner(userDetails.user_id);
          }
          //override with parsed params
          if(parsed.min_arr){
            setMinARR(String(parsed.min_arr));
          }
          if(parsed.max_arr){
            setMaxARR(String(parsed.max_arr));
          }
          if(parsed.csm_id){
            //"005A0000008dQDXIA2" => Ross McElheny
            uf['filters']['csm_id'] = [parsed.csm_id];
            uf['filters']['owner_id'] = null;
            setFilterCSM(parsed.csm_id);
            setFilterOwner(null);
          }
          if(parsed.account_id){
            // "001A000001Ew6XsIAJ" => COX Auto
            uf['filters']['account_id'] = parsed.account_id;
            setFilterAccount(parsed.account_id);
            if(!parsed.csm_id && !parsed.owner_id){
              uf['filters']['csm_id'] = [];
              uf['filters']['owner_id'] = null;
              setFilterCSM(null);
              setFilterOwner(null);
            }else{
              if(parsed.csm_id){
                uf['filters']['csm_id'] = [parsed.csm_id];
                uf['filters']['owner_id'] = null;
                setFilterCSM(parsed.csm_id);
              }
              if(parsed.owner_id){
                uf['filters']['csm_id'] = [];
                uf['filters']['owner_id'] = parsed.owner_id;
                setFilterOwner(parsed.owner_id);
              }
            }
          }
          if(parsed.owner_id){
            // "0056R00000ChpTdQAJ" => Justin Golding
            uf['filters']['owner_id'] = parsed.owner_id;
            uf['filters']['csm_id'] = null;
            setFilterOwner(parsed.owner_id);
          }
          console.log('uf',uf);
          setF(uf.filters);
          if(!uf['filters'].csm_id && !uf['filters'].account_id && !uf['filters'].owner_id){
            alert('invalid URL detected, redirecting');
            navigate.push('/workloads');
          }
          getUserWorkloads(uf).then((x)=>{
            console.log('workloads',x);
            setData(x);
            let ogPass = x;
            if(parsed.v){
              //activate corresponding part of the segmented view control @ mdb design
              setSegmentedCtrlVal(String(parsed.v));
              if(String(parsed.v) === "unhealthy"){
                ogPass = filterData([{for:"workload_health",val:"red"}],x);
                setVisibleData(ogPass)
              }else if(String(parsed.v) === "at-risk"){
                ogPass = filterData([{for:"workload_health",val:"yellow"}],x);
                setVisibleData(ogPass)
              }else if(String(parsed.v) === "on-track"){
                ogPass = filterData([{for:"workload_health",val:"green"}],x);
                setVisibleData(ogPass)
              }else if(String(parsed.v)=== "snoozed"){
                ogPass = filterData([{for:"snoozed",val:true}],x);
                setVisibleData(ogPass)
              }else if(String(parsed.v)=== "overdue"){
                ogPass = filterData([{for:"workload_is_overdue",val:true}],x);
                setVisibleData(ogPass)
              }

              if(parsed.min_arr && parsed.max_arr){
                let firstPass = filterData([{for:"min_arr",val:String(parsed.min_arr)}],ogPass);
                setVisibleData(filterData([{for:"max_arr",val:String(parsed.max_arr)}],firstPass));
              }else if(parsed.min_arr){
                setVisibleData(filterData([{for:"min_arr",val:String(parsed.min_arr)}],ogPass));
              }else if(parsed.max_arr){
                setVisibleData(filterData([{for:"max_arr",val:String(parsed.max_arr)}],ogPass));
              }

            }else{
              if(parsed.min_arr && parsed.max_arr){
                let firstPass = filterData([{for:"min_arr",val:String(parsed.min_arr)}],ogPass);
                setVisibleData(filterData([{for:"max_arr",val:String(parsed.max_arr)}],firstPass));
              }else if(parsed.min_arr){
                setVisibleData(filterData([{for:"min_arr",val:String(parsed.min_arr)}],ogPass));
              }else if(parsed.max_arr){
                setVisibleData(filterData([{for:"max_arr",val:String(parsed.max_arr)}],ogPass));
              }else{
                setVisibleData(x);
              }
            }
          });
        })
      }
      
      
    }
  },[authState,user,authed])

  const [visibleData,setVisibleData] = React.useState([]);

  useEffect(()=>{
    console.log('visibleDataUpdate',visibleData);
  },[visibleData])

  useEffect(()=>{
    if((sessionStorage.getItem('og_user'))){
      analytics.identify(String(sessionStorage.getItem('og_user')),{company:"MongoDB"}, () => {
        analytics.page().then(()=>{console.log('tracked page visit')});
      })
    }
  },[sessionStorage.getItem('og_user')])
 
  const filterData = (datumAll: any[], d?:any[]) =>{
    console.log('datum',datumAll)
    let filtered = [];
    let fd = data.slice(0);
    if(d){
      fd = d.slice(0);
    }
    datumAll.forEach((datum)=>{
      for(let i = 0; i < fd.length; i++){
        let el = fd[i];
        if(datum.for && datum.val){
          if(datum.for == "min_arr"){
            let wlARR = String( parseFloat(String(el.workload_actual_arr)) + parseFloat(String(el.workload_starting_arr)) );
            if(parseInt(wlARR,10) >= parseInt(datum.val,10)){
              filtered.push(el);
            }
          }else if(datum.for == "max_arr"){
            let wlARR = String( parseFloat(String(el.workload_actual_arr)) + parseFloat(String(el.workload_starting_arr)) );
            if(parseInt(wlARR,10) <= parseInt(datum.val,10)){
              filtered.push(el);
            }
          }else{
            if(String(el[datum.for]).toLowerCase() == String(datum.val).toLowerCase()){
              filtered.push(el);
            }
          }
          
        }
      }
    })
    
    //setVisibleData(filtered);
    console.log('filtered',filtered);
    return filtered;
  };
  const convertToCSV = function(objArray) {
      var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
      var str = '';

      for (var i = 0; i < array.length; i++) {
          var line = '';
          for (var index in array[i]) {
              if (line != '') line += ','

              line += array[i][index];
          }

          str += line + '\r\n';
      }

      return str;
  }
  const exportCSVFile = function(headers, items, fileTitle) {
    if (headers) {
        items.unshift(headers);
    }

    // Convert Object to JSON
    var jsonObject = JSON.stringify(items);

    var csv = convertToCSV(jsonObject);

    var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    
    var link = document.createElement("a");
    if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilenmae);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

}
  const renderIndicatorHeaders = (datum: any) => {
    return showIndicators ? <>

    
    </> : null
  }
  const renderRedIndicators = (datum: any) => {
    let indicators = extractIndicators(datum.health)
    indicators = indicators?.filter(indicator => (indicator?.stage?.toLowerCase() === datum.health?.workload_stage?.toLowerCase() || indicator?.stage?.toLowerCase() === datum.health?.workload_phase?.toLowerCase()) && indicator.status === "Red")
    let results = indicators?.length > 0 ? indicators.map(indicator => { return <Badge className='text-truncate' variant="red">{indicator?.label}</Badge>}) : <Badge>None</Badge>
    return results
  }
  const renderYellowIndicators = (datum: any) => {
    let indicators = extractIndicators(datum.health)
    indicators = indicators?.filter(indicator => (indicator?.stage?.toLowerCase() === datum.health?.workload_stage?.toLowerCase() || indicator?.stage?.toLowerCase() === datum.health?.workload_phase?.toLowerCase()) && indicator.status === "Yellow")
    let results = indicators?.length > 0 ? indicators.map(indicator => { return <Badge className='text-truncate' variant="yellow">{indicator?.label}</Badge>}) : <Badge>None</Badge>
    return results
  }
  const renderdata = () => {
    const expand2consumption = function(s:string){
        if(String(s).toLowerCase() == "expand" || String(s).toLowerCase() == "adopt" || String(s).toLowerCase() == "retain"){
          return "Consumption";
        }else{
          return s;
        }
      };
    const element =  <div style={{textAlign:"left",marginTop:"8em"}}>

      <p><b>Workloads</b></p>
      <p style={{
        position:"fixed",
        height:"10em",
        top:"1em",
        right:"1em",
        background:"",
        zIndex:999999,
        fontSize:".9em"
      }}><a href="" onClick={()=>{
        


        var headers = {
          workload_id: 'WorkloadID'.replace(/,/g, ''), // remove commas to avoid errors
          workload_name: "WorkloadName",
          account_owner_id: "Owner ID",
          account_owner: "Owner",
          workload_cta_csm_id: "CSMID",
          workload_cta_csm: "CSM",
          account_id: "AccountID",
          account_name:"AccountName",
          workload_health:"WorkloadHealth",
          workload_phase:"Phase",
          workload_stage:"Stage",
          workload_age:"Age",
          clusters:"Clusters",
          workload_starting_arr:"workload_starting_arr",
          workload_actual_arr:"workload_actual_arr",
          workload_forecast_arr:"forecast",
          incremental_run_rate:"irr",
          incremental_vs_expected:"incremental_vs_expected",
          has_cta:"has_cta?"
        };

        let itemsNotFormatted = JSON.parse(JSON.stringify(visibleData))

        var itemsFormatted = [];

        
        // format the data
        itemsNotFormatted.forEach((item) => {
          let datum = JSON.parse(JSON.stringify(item));
          let actual_arr = datum['workload_actual_arr'] && datum['workload_actual_arr']["$numberDecimal"]?parseFloat(datum['workload_actual_arr']["$numberDecimal"]):0.00;
          let starting_arr = datum['workload_starting_arr'] && datum['workload_starting_arr']["$numberDecimal"]?parseFloat(datum['workload_starting_arr']["$numberDecimal"]):0.00;
          let irr = datum.workload_actual_arr && datum.workload_starting_arr && datum['workload_starting_arr']["$numberDecimal"] && datum['workload_actual_arr']["$numberDecimal"]? (parseFloat(String(datum['workload_actual_arr']["$numberDecimal"])) ) : 'N/A';
          let forecast_arr = datum['workload_forecast_arr'] && datum['workload_forecast_arr']["$numberDecimal"]?parseFloat(datum['workload_forecast_arr']["$numberDecimal"]):0.00;
          //USDollar.format(
          let incr_vs_expect = forecast_arr && forecast_arr > 0.00 ? ((parseFloat(String(actual_arr))  )/parseFloat(String(forecast_arr)) * 100 ).toFixed(2) +"%" : 'N/A';
          itemsFormatted.push({
            workload_id: item.workload_id.replace(/,/g, ''), // remove commas to avoid errors,
            workload_name: item.workload_name.replace(/,/g, ''),
            account_owner_id: item.account_owner_id,
            account_owner: item.account_owner,
            workload_cta_csm_id: item.workload_cta_csm_id,
            workload_cta_csm: item.workload_cta_csm.replace(/,/g, ''),
            account_id: item.account_id,
            account_name:item.account_name.replace(/,/g, ''),
            workload_health:item.workload_health,
            workload_phase:item.workload_phase,
            workload_stage:item.workload_stage,
            workload_age:item.workload_age,
            clusters:item.clusters?.length,
            workload_starting_arr:starting_arr,
            workload_actual_arr:actual_arr,
            workload_forecast_arr:forecast_arr,
            incremental_run_rate:irr,
            incremental_vs_expected:incr_vs_expect,
            has_cta: item.gs_cta_id && !item.gs_cta_close_date?true:false
          });
        });

        var fileTitle = 'workloads'; // or 'my-unique-title'

        exportCSVFile(headers, itemsFormatted, fileTitle);
      }}>Export<Icon glyph="Export"></Icon></a></p>
      <p>
      <SegmentedControl
          label="View"
          name="view"
          size="default"
          value={segmentedCtrlVal}
          onChange={(e)=>{
            setSegmentedCtrlVal(e);
            //NAVIGATE TO NEW ROUTE!!!!
            let path = "";
            if(e != ""){
              //now needs to include parsed.csm_id, parsed.account_id, parsed.owner_id
              
              if(filter_account != ""){
                if(filter_csm){
                  if(parseInt(filter_min_arr,10)>=0 && !filter_max_arr){
                    navigate.push("/workloads?v="+encodeURIComponent(e)+"&account_id="+filter_account+"&csm_id="+encodeURIComponent(filter_csm)+"&min_arr="+encodeURIComponent(filter_min_arr));
                  }else if(parseInt(filter_min_arr,10)>=0 && parseInt(filter_max_arr,10)>=0){
                    navigate.push("/workloads?v="+encodeURIComponent(e)+"&account_id="+filter_account+"&csm_id="+encodeURIComponent(filter_csm)+"&min_arr="+encodeURIComponent(filter_min_arr)+"&max_arr="+encodeURIComponent(filter_max_arr));
                  }else if(!filter_min_arr && parseInt(filter_max_arr,10)>=0){
                    navigate.push("/workloads?v="+encodeURIComponent(e)+"&account_id="+filter_account+"&csm_id="+encodeURIComponent(filter_csm)+"&max_arr="+encodeURIComponent(filter_max_arr));
                  }else{
                    navigate.push("/workloads?v="+encodeURIComponent(e)+"&account_id="+filter_account+"&csm_id="+encodeURIComponent(filter_csm))
                  }
                }else if(f.owner_id != null){
                  if(parseInt(filter_min_arr,10)>=0 && !filter_max_arr){
                    navigate.push("/workloads?v="+encodeURIComponent(e)+"&account_id="+filter_account+"&owner_id="+encodeURIComponent(filter_owner)+"&min_arr="+encodeURIComponent(filter_min_arr));
                  }else if(parseInt(filter_min_arr,10)>=0 && parseInt(filter_max_arr,10)>=0){
                    navigate.push("/workloads?v="+encodeURIComponent(e)+"&account_id="+filter_account+"&owner_id="+encodeURIComponent(filter_owner)+"&min_arr="+encodeURIComponent(filter_min_arr)+"&max_arr="+encodeURIComponent(filter_max_arr));
                  }else if(!filter_min_arr && parseInt(filter_max_arr,10)>=0){
                    navigate.push("/workloads?v="+encodeURIComponent(e)+"&account_id="+filter_account+"&owner_id="+encodeURIComponent(filter_owner)+"&max_arr="+encodeURIComponent(filter_max_arr));
                  }else{
                    navigate.push("/workloads?v="+encodeURIComponent(e)+"&account_id="+filter_account+"&owner_id="+encodeURIComponent(filter_owner));
                  }
                }else{
                  if(parseInt(filter_min_arr,10)>=0 && !filter_max_arr){
                    navigate.push("/workloads?v="+encodeURIComponent(e)+"&account_id="+filter_account+"&min_arr="+encodeURIComponent(filter_min_arr));
                  }else if(parseInt(filter_min_arr,10)>=0 && parseInt(filter_max_arr,10)>=0){
                    navigate.push("/workloads?v="+encodeURIComponent(e)+"&account_id="+filter_account+"&min_arr="+encodeURIComponent(filter_min_arr)+"&max_arr="+encodeURIComponent(filter_max_arr));
                  }else if(!filter_min_arr && parseInt(filter_max_arr,10)>=0){
                    navigate.push("/workloads?v="+encodeURIComponent(e)+"&account_id="+filter_account+"&max_arr="+encodeURIComponent(filter_max_arr));
                  }else{
                    navigate.push("/workloads?v="+encodeURIComponent(e)+"&account_id="+filter_account);
                  }
                }
              }else if(filter_csm != ""){ //csm id is the only array 
                
                if(parseInt(filter_min_arr,10)>=0 && !filter_max_arr){
                  navigate.push("/workloads?v="+encodeURIComponent(e)+"&csm_id="+filter_csm+"&min_arr="+encodeURIComponent(filter_min_arr));
                }else if(parseInt(filter_min_arr,10)>=0 && parseInt(filter_max_arr,10)>=0){
                  navigate.push("/workloads?v="+encodeURIComponent(e)+"&csm_id="+filter_csm+"&min_arr="+encodeURIComponent(filter_min_arr)+"&max_arr="+encodeURIComponent(filter_max_arr));
                }else if(!filter_min_arr && parseInt(filter_max_arr,10)>=0){
                  navigate.push("/workloads?v="+encodeURIComponent(e)+"&csm_id="+filter_csm+"&max_arr="+encodeURIComponent(filter_max_arr));
                }else{
                  navigate.push("/workloads?v="+encodeURIComponent(e)+"&csm_id="+filter_csm);
                }
              }else if(filter_owner != ""){
                if(parseInt(filter_min_arr,10)>=0 && !filter_max_arr){
                  navigate.push("/workloads?v="+encodeURIComponent(e)+"&owner_id="+filter_owner+"&min_arr="+encodeURIComponent(filter_min_arr));
                }else if(parseInt(filter_min_arr,10)>=0 && parseInt(filter_max_arr,10)>=0){
                  navigate.push("/workloads?v="+encodeURIComponent(e)+"&owner_id="+filter_owner+"&min_arr="+encodeURIComponent(filter_min_arr)+"&max_arr="+encodeURIComponent(filter_max_arr));
                }else if(!filter_min_arr && parseInt(filter_max_arr,10)>=0){
                  navigate.push("/workloads?v="+encodeURIComponent(e)+"&owner_id="+filter_owner+"&max_arr="+encodeURIComponent(filter_max_arr));
                }else{
                  navigate.push("/workloads?v="+encodeURIComponent(e)+"&owner_id="+filter_owner);
                }
               
              }else{
                if(parseInt(filter_min_arr,10)>=0 && !filter_max_arr){
                  navigate.push("/workloads?v="+encodeURIComponent(e)+"&min_arr="+encodeURIComponent(filter_min_arr));
                }else if(parseInt(filter_min_arr,10)>=0 && parseInt(filter_max_arr,10)>=0){
                  navigate.push("/workloads?v="+encodeURIComponent(e)+"&min_arr="+encodeURIComponent(filter_min_arr)+"&max_arr="+encodeURIComponent(filter_max_arr));
                }else if(!filter_min_arr && parseInt(filter_max_arr,10)>=0){
                  navigate.push("/workloads?v="+encodeURIComponent(e)+"&max_arr="+encodeURIComponent(filter_max_arr));
                }else{
                  navigate.push("/workloads?v="+encodeURIComponent(e));
                }
                
              }
              if(String(e) === "unhealthy"){
                if(parseInt(filter_min_arr,10)>=0 && !filter_max_arr){
                  let round1 = filterData([{for:"min_arr",val:filter_min_arr}])
                  setVisibleData(filterData([{for:"workload_health",val:"red"}],round1));
                }else if(parseInt(filter_min_arr,10)>=0 && parseInt(filter_max_arr,10)>=0){
                  let round1 = filterData([{for:"min_arr",val:filter_min_arr}])
                  let round2 = filterData([{for:"max_arr",val:filter_max_arr}],round1);
                  setVisibleData(filterData([{for:"workload_health",val:"red"}],round2));
                }else if(!filter_min_arr && parseInt(filter_max_arr,10)>=0){
                  let round1 = filterData([{for:"max_arr",val:filter_max_arr}])
                  setVisibleData(filterData([{for:"workload_health",val:"red"}],round1));
                }else{
                  setVisibleData(filterData([{for:"workload_health",val:"red"}]))
                }
              }else if(String(e) === "at-risk"){
                if(parseInt(filter_min_arr,10)>=0 && !filter_max_arr){
                  let round1 = filterData([{for:"min_arr",val:filter_min_arr}])
                  setVisibleData(filterData([{for:"workload_health",val:"yellow"}],round1));
                }else if(parseInt(filter_min_arr,10)>=0 && parseInt(filter_max_arr,10)>=0){
                  let round1 = filterData([{for:"min_arr",val:filter_min_arr}])
                  let round2 = filterData([{for:"max_arr",val:filter_max_arr}],round1);
                  setVisibleData(filterData([{for:"workload_health",val:"yellow"}],round2));
                }else if(!filter_min_arr && parseInt(filter_max_arr,10)>=0){
                  let round1 = filterData([{for:"max_arr",val:filter_max_arr}])
                  setVisibleData(filterData([{for:"workload_health",val:"yellow"}],round1));
                }else{
                  setVisibleData(filterData([{for:"workload_health",val:"yellow"}]))
                }
              }else if(String(e) === "on-track"){
                if(parseInt(filter_min_arr,10)>=0 && !filter_max_arr){
                  let round1 = filterData([{for:"min_arr",val:filter_min_arr}])
                  setVisibleData(filterData([{for:"workload_health",val:"green"}],round1));
                }else if(parseInt(filter_min_arr,10)>=0 && parseInt(filter_max_arr,10)>=0){
                  let round1 = filterData([{for:"min_arr",val:filter_min_arr}])
                  let round2 = filterData([{for:"max_arr",val:filter_max_arr}],round1);
                  setVisibleData(filterData([{for:"workload_health",val:"green"}],round2));
                }else if(!filter_min_arr && parseInt(filter_max_arr,10)>=0){
                  let round1 = filterData([{for:"max_arr",val:filter_max_arr}])
                  setVisibleData(filterData([{for:"workload_health",val:"green"}],round1));
                }else{
                  setVisibleData(filterData([{for:"workload_health",val:"green"}]))
                }
              }else if(String(e) === "snoozed"){
                if(parseInt(filter_min_arr,10)>=0 && !filter_max_arr){
                  let round1 = filterData([{for:"min_arr",val:filter_min_arr}])
                  setVisibleData(filterData([{for:"snoozed",val:true}],round1));
                }else if(parseInt(filter_min_arr,10)>=0 && parseInt(filter_max_arr,10)>=0){
                  let round1 = filterData([{for:"min_arr",val:filter_min_arr}])
                  let round2 = filterData([{for:"max_arr",val:filter_max_arr}],round1);
                  setVisibleData(filterData([{for:"snoozed",val:true}],round2));
                }else if(!filter_min_arr && parseInt(filter_max_arr,10)>=0){
                  let round1 = filterData([{for:"max_arr",val:filter_max_arr}])
                  setVisibleData(filterData([{for:"snoozed",val:true}],round1));
                }else{
                  setVisibleData(filterData([{for:"snoozed",val:true}]))
                }
              }else if(String(e) === "overdue"){
                if(parseInt(filter_min_arr,10)>=0 && !filter_max_arr){
                  let round1 = filterData([{for:"min_arr",val:filter_min_arr}])
                  setVisibleData(filterData([{for:"workload_is_overdue",val:true}],round1));
                }else if(parseInt(filter_min_arr,10)>=0 && parseInt(filter_max_arr,10)>=0){
                  let round1 = filterData([{for:"min_arr",val:filter_min_arr}])
                  let round2 = filterData([{for:"max_arr",val:filter_max_arr}],round1);
                  setVisibleData(filterData([{for:"workload_is_overdue",val:true}],round2));
                }else if(!filter_min_arr && parseInt(filter_max_arr,10)>=0){
                  let round1 = filterData([{for:"max_arr",val:filter_max_arr}])
                  setVisibleData(filterData([{for:"workload_is_overdue",val:true}],round1));
                }else{
                  setVisibleData(filterData([{for:"workload_is_overdue",val:true}]))
                }
              }
            }else{
              if(filter_csm){       
                if(parseInt(filter_min_arr,10)>=0 && !filter_max_arr){
                  navigate.push("/workloads?csm_id="+encodeURIComponent(filter_csm)+"&min_arr="+encodeURIComponent(filter_min_arr));
                }else if(parseInt(filter_min_arr,10)>=0 && parseInt(filter_max_arr,10)>=0){
                  navigate.push("/workloads?csm_id="+encodeURIComponent(filter_csm)+"&min_arr="+encodeURIComponent(filter_min_arr)+"&max_arr="+encodeURIComponent(filter_max_arr));
                }else if(!filter_min_arr && parseInt(filter_max_arr,10)>=0){
                  navigate.push("/workloads?csm_id="+encodeURIComponent(filter_csm)+"&max_arr="+encodeURIComponent(filter_max_arr));
                }else{
                  navigate.push("/workloads?csm_id="+encodeURIComponent(filter_csm));
                }
              }
              else if(filter_account){
                
                if(parseInt(filter_min_arr,10)>=0 && !filter_max_arr){
                  navigate.push("/workloads?account_id="+encodeURIComponent(filter_account)+"&min_arr="+encodeURIComponent(filter_min_arr));
                }else if(parseInt(filter_min_arr,10)>=0 && parseInt(filter_max_arr,10)>=0){
                  navigate.push("/workloads?account_id="+encodeURIComponent(filter_account)+"&min_arr="+encodeURIComponent(filter_min_arr)+"&max_arr="+encodeURIComponent(filter_max_arr));
                }else if(!filter_min_arr && parseInt(filter_max_arr,10)>=0){
                  navigate.push("/workloads?account_id="+encodeURIComponent(filter_account)+"&max_arr="+encodeURIComponent(filter_max_arr));
                }else{
                  navigate.push("/workloads?account_id="+encodeURIComponent(filter_account));
                }
              }else{
                if(parseInt(filter_min_arr,10)>=0 && !filter_max_arr){
                  navigate.push("/workloads?min_arr="+encodeURIComponent(filter_min_arr));
                }else if(parseInt(filter_min_arr,10)>=0 && parseInt(filter_max_arr,10)>=0){
                  navigate.push("/workloads?min_arr="+encodeURIComponent(filter_min_arr)+"&max_arr="+encodeURIComponent(filter_max_arr));
                }else if(!filter_min_arr && parseInt(filter_max_arr,10)>=0){
                  navigate.push("/workloads?max_arr="+encodeURIComponent(filter_max_arr));
                }else{
                  navigate.push("/workloads")
                }
              }
              if(filter_min_arr && !filter_max_arr){
                setVisibleData(filterData([{for:"min_arr",val:filter_min_arr}]));
              }else if(filter_min_arr && filter_max_arr){
                let round1 = filterData([{for:"min_arr",val:filter_min_arr}])
                setVisibleData(filterData([{for:"max_arr",val:filter_max_arr}],round1));
              }else if(!filter_min_arr && filter_max_arr){
                setVisibleData(filterData([{for:"max_arr",val:filter_max_arr}]));
              }else{
                setVisibleData(data);      
              }   
            }
            
            
          }}
        >
          <SegmentedControlOption value="">
            All
          </SegmentedControlOption>
          <SegmentedControlOption value="unhealthy">
            Unhealthy
          </SegmentedControlOption>
          <SegmentedControlOption value="at-risk">
            At-Risk
          </SegmentedControlOption>
          <SegmentedControlOption value="on-track">
            On-Track
          </SegmentedControlOption>
          <SegmentedControlOption value="snoozed">
            Snoozed 💤
          </SegmentedControlOption>
          <SegmentedControlOption value="overdue">
            Overdue ⏰
          </SegmentedControlOption>
        </SegmentedControl>
      </p>
      
      <Filters></Filters>
      <Row className="table-1">
        <Table
          data={visibleData}
          columns={[
            <TableHeader label="Account Name" sortBy={(datum: any) => datum?.account_name} />,
            <TableHeader label="Workload" sortBy={(datum: any) => datum.workload_id} />,
            <TableHeader label="Owner" sortBy={(datum: any) => datum.name} />,
            <TableHeader label="CSM" sortBy={(datum: any) => datum?.workload_cta_csm} />,
            <TableHeader label="Phase" sortBy={(datum: any) => datum.workload_phase} />,
            <TableHeader label="Stage" sortBy={(datum: any) => datum.workload_stage} />,
            <TableHeader label="Status"  />,
            <TableHeader label="Age" sortBy={(datum: any) => datum.workload_age} />,
            <TableHeader label="Clusters" sortBy={(datum: any) => datum.clusters?.length} />,
            <TableHeader label="Incremental Run Rate" sortBy={(datum: any) => String( parseFloat(String(datum.workload_actual_arr)) + parseFloat(String(datum.workload_starting_arr)) )} />,
            <TableHeader label="Incremental vs Expected ARR" sortBy={(datum: any) => String( ((parseFloat(String(datum.workload_actual_arr)) + parseFloat(String(datum.workload_starting_arr)) )/parseFloat(String(datum.workload_forecast_arr)) * 100 ).toFixed(2) +"%" )} />,
            showIndicators ? <TableHeader label="Unhealthy Indicators"  /> : null,
            showIndicators ? <TableHeader label="At Risk Indicators"  /> : null,
          ]}
        >
          {({ datum }: any) => (
            <TRow expanded={true}  key={datum.workload_id}>
              <Cell>{ datum?.account_name ?  datum?.account_name : 'N/A'}</Cell>
              <Cell >
                <Link href={"/workload/"+datum?.workload_id} onClick={() => handleWorkloadSelect(datum.workload_name, datum?.account_id,  datum?.account_name)}>
                  {datum.workload_name ? datum.workload_name : 'N/A'}
                  
                  {datum.gs_cta_id && !datum.gs_cta_close_date &&
                    <Badge>CTA</Badge>
                  }
                  {datum.snoozed &&
                    <Badge>💤</Badge>
                  }
                </Link>
              </Cell>
              <Cell>{datum.account_owner ? datum.account_owner : 'N/A'}</Cell>
              <Cell>{ datum?.workload_cta_csm ?  datum?.workload_cta_csm : 'N/A'}</Cell>
              <Cell className='text-capitalize'>{datum.workload_phase ? datum.workload_phase : 'N/A'}</Cell>
              <Cell className='text-capitalize'>
                {datum.workload_stage ? expand2consumption(datum.workload_stage) : 'N/A'}
                {datum.workload_is_overdue &&
                  <Badge>⏰</Badge>
                }
              </Cell>
              <Cell><Badge className='text-truncate' variant={datum.workload_health?.toLowerCase() === 'gray' ? 'lightgray': datum.workload_health?.toLowerCase()}>{getLabelFromColor(datum.workload_health ? datum.workload_health : 'N/A')}</Badge></Cell>
              <Cell>{datum.workload_age ? datum.workload_age : 'N/A'}</Cell>
              <Cell>{datum.clusters ? datum.clusters?.length : 0}</Cell>
              <Cell>{ datum.workload_actual_arr && datum.workload_starting_arr ? USDollar.format(parseFloat(String(datum.workload_actual_arr)) ) : 'N/A'}</Cell>
              <Cell>{ datum.workload_forecast_arr && datum.workload_forecast_arr ? ((parseFloat(String(datum.workload_actual_arr))  )/parseFloat(String(datum.workload_forecast_arr)) * 100 ).toFixed(2) +"%" : 'N/A'}</Cell>
              {showIndicators ? <Cell><Col>{renderRedIndicators(datum)}</Col></Cell> : null}
              {showIndicators ? <Cell><Col>{renderYellowIndicators(datum)}</Col></Cell> : null}
            </TRow>
          )}
        </Table>
      </Row>
    </div>
    if(!wl.workload_id){
      //we gotta do WorkloadView
      if(!loading){
        return element
      }
    }
  }

  return renderdata()
}
