import React from "react";
import ItemList from "../components/ItemList";

import Alert from 'react-bootstrap/Alert';
import { TeamTable } from "../components/TeamsTable";
import { useAuth } from "../hooks/useAuth";

function About() {
   const {getTeamTotals,UR,userObject} = useAuth();
   const [teamTotals,setTeamTotals] = React.useState([]);
   React.useEffect(()=>{
    if(UR && UR.length > 0){
      getTeamTotals({filters:{is_csm:(userObject?.mongodb_team == "CSM" ? true : false),reports:UR}}).then((x)=>{
        console.log('x',x)
        setTeamTotals(x);
      })
    }
   },[UR])
   return (
      <div className="about-content">
        {UR && UR.length <= 0 &&
          <p>Sorry, you do not have people reporting to you.</p>
        }
        {UR && UR.length > 0 &&
          <TeamTable is_csm={(userObject?.mongodb_team == "CSM" ? true : false)} reports={UR} data={teamTotals}></TeamTable>
        }
      </div>
   );
}

export default About;
