import React, { useEffect, useState } from "react";
import { WorkloadsTable } from "../components/WorkloadsTable";
import { useOktaAuth } from '@okta/okta-react';
import { useAuth } from "../hooks/useAuth";
import {useLocation,useSearchParams,useHistory} from "react-router-dom";
import queryString from 'query-string';
import Card from "@leafygreen-ui/card";
import { Col, Row } from "react-bootstrap";
import Icon from "@leafygreen-ui/icon";
import Badge from "@leafygreen-ui/badge";
import { useAnalytics } from "use-analytics";

function Ctas() {
  const { authState } = useOktaAuth();
  const {login,logout,getWorkloadsCtas,user,getUser,userObject} = useAuth();
  const [ctaData,setCTAdata] = useState([]);
  const navigate = useHistory();
  const analytics = useAnalytics();
  
  useEffect(()=>{
    if((sessionStorage.getItem('og_user'))){
      analytics.identify(String(sessionStorage.getItem('og_user')),{company:"MongoDB"}, () => {
        analytics.page().then(()=>{console.log('tracked page visit')});
      })
    }
  },[sessionStorage.getItem('og_user')])

  useEffect(()=>{
    console.log('authed',"authState");
    console.log('userObject',userObject.user_id);
    if (authState?.isAuthenticated && userObject.user_id) {
      let filters = {
          "account_id": null,
          "csm_id": [String(userObject.user_id)],
          "owner_id": null,
      };
      getWorkloadsCtas(filters).catch((e)=>{
        console.log('err',e);
      }).then((x)=>{
        console.log('BRITT SMITH CTAS',x);
        setCTAdata(x);
      });
    }
    console.log('USER',user,userObject);
    //this makes sure the previous user session is cleared
  },[authState,login,logout,getWorkloadsCtas,user]);

  //handleWorkloadSelect(datum.workload_name, datum?.account_id,  datum?.account_name)
  //navigate.push("workload/:wl_id")
  
   return (
      <div className="feed-content">
        <div id="cta-list" style={{marginTop:"8em",textAlign:"left"}}>
          <h3>CTAs</h3>
          <hr />
          <br />
          <Row>
            <Col lg={7}>
            {ctaData.map((cta, i) => {     
                console.log("Entered");                 
                // Return the element. Also pass key     
                return (<Card  style={{marginTop:"1em",marginBottom:"1em"}} onClick={()=>{
                  navigate.push("/workload/"+encodeURIComponent(cta.workload_id))
                }} >
                  <div>
                    <div style={{width:"20%",display:"inline-block"}}>
                      <img src="https://cdn-icons-png.flaticon.com/512/3883/3883436.png" style={{height:"45px",float:"left","marginBottom":"25%"}} />
                    </div>
                    <div style={{"width":"70%",display:"inline-block",margin:"0 auto",overflow:"auto"}}>
                      <b><Badge variant="blue">{cta.cta_status}</Badge>   &nbsp;   &nbsp; {cta.cta_name}</b>
                      <p>
                        <b>Open Date: </b>{cta.cta_open_date} &nbsp;&nbsp;&nbsp;&nbsp;
                        <b>Close Date: </b>{cta.cta_close_date}
                      </p>
                      <p>
                        <b>Account:</b> {cta.account_name}
                      </p>
                      <p>
                        <b>Workload:</b> {cta.workload_name}
                      </p>
                    </div>
                  </div>
                </Card> ) 
              })}
            </Col>
            <Col lg={1}>
              &nbsp;
            </Col>
            <Col lg={4}>
              <Card>
                <h4><b>CTA Settings</b></h4>
                <hr />
                <p>Coming soon.</p>
              </Card>  
            </Col>
          </Row>
          
        </div>
        <div id="cta-side">

        </div>

      </div>
   );
}

export default Ctas;
