import { Row, Col } from 'react-bootstrap';
import Card from '@leafygreen-ui/card';
import { Table, TableHeader, Row as TRow, Cell } from '@leafygreen-ui/table';
import { Body, Link } from '@leafygreen-ui/typography';
import Badge from '@leafygreen-ui/badge';
import ExpandableCard from '@leafygreen-ui/expandable-card';
import React, { useEffect } from 'react';
import { useAnalytics } from "use-analytics";
import { useAuth } from '../hooks/useAuth';

let USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

interface Props {
  data: any[];
  reports: any[];
  is_csm: boolean;
}

export const TeamTable = ({ reports, data, is_csm }: Props) => {
  //make sure data
  console.log('data',data)
  
  const [totalWL,setTotalWL] = React.useState(0);
  const [totalWL_total,setTotalWL_total] = React.useState(0);
  const [totalWL_est,setTotalWL_est] = React.useState(0);
  const [totalWL_RED,setTotalWL_RED] = React.useState(0);
  const [totalWL_YELLOW,setTotalWL_YELLOW] = React.useState(0);
  const [totalWL_GREEN,setTotalWL_GREEN] = React.useState(0);
  let temp_totalWL = 0;
let temp_totalEst = 0;
let temp_totalCtas = 0;
let temp_totalRed = 0;
let temp_totalYellow = 0;
let temp_totalGreen = 0;
const analytics = useAnalytics();
const {user,authed,getUserWorkloads,getUser} = useAuth();

useEffect(()=>{
    if((sessionStorage.getItem('og_user'))){
      analytics.identify(String(sessionStorage.getItem('og_user')),{company:"MongoDB"}, () => {
        analytics.page().then(()=>{console.log('tracked page visit')});
      })
    }
  },[sessionStorage.getItem('og_user')])

  const renderTeamWorkloads = () => {
    

    let cleanData = data? data.filter((d) => d?._id?.name) : []
    if(reports){
      for(let i = 0; i < reports.length; i++){
        let f = null;
        for(let j = 0; j < cleanData.length; j++){
          if(cleanData[j]['_id']['name'] == reports[i]['full_name']){
            f = cleanData[j];
            console.log('f',f)
            //lets do the totals real quick
            temp_totalWL += parseInt(String(f['total_workloads']),10);
            temp_totalEst += parseInt(String(f['total_arr']),10);
            temp_totalRed += parseInt(String(f['status_red']),10);
            temp_totalYellow += parseInt(String(f['status_yellow']),10);
            temp_totalGreen += parseInt(String(f['status_green']),10);
            temp_totalCtas += parseInt(String(f['outstanding_ctas']),10);
            break;
          }
        }
        if(!f){
          cleanData.push({
                "_id": {
                    "id": reports[i]['user_id'],
                    "name": reports[i]['full_name']
                },
                "outstanding_ctas": 0,
                "overdue": 0,
                "total_workloads": 0,
                "total_arr": 0,
                "status_red": 0,
                "status_green": 0,
                "status_yellow": 0,
                "stage_plan": 0,
                "stage_build": 0,
                "stage_consumption": 0,
                "stage_launch": 0
            })
        }

      }
    }

    const element = <ExpandableCard defaultOpen={true} title={'Team'} description={<i></i>}>
        <Row>
            <Col>
                <Card>
                    <b>Total Workloads</b>
                    <br />
                    {temp_totalWL}
                </Card>
            </Col>
            <Col>
                <Card>
                    <b>Total ARR</b>
                    <br />
                    {USDollar.format(temp_totalEst)}
                </Card>
            </Col>
            <Col>
                <Card>
                    <b>Total CTAs</b>
                    <br />
                    {temp_totalCtas}
                </Card>
            
            </Col>
            <Col>
                <Card>
                    <b>Total Unhealthy</b>
                    <br />
                    {temp_totalRed}
                </Card>
            
            </Col>
            <Col>
                <Card>
                    <b>Total At Risk</b>
                    <br />
                    {temp_totalYellow}
                </Card>
            
            </Col>
            <Col>
                <Card>
                    <b>Total On Track</b>
                    <br />
                    {temp_totalGreen}
                </Card>
            
            </Col>
        </Row>

        <br />
            <br />
            <br />
      <Row className="table-1">
        <Table
          data={cleanData}
          columns={[
            <TableHeader label="Name" sortBy={(datum: any) => datum._id?.name} />,
            <TableHeader label="Workloads" sortBy={(datum: any) => datum.total_workloads} />,
            <TableHeader label="On Track" sortBy={(datum: any) => datum.status_green} />,
            <TableHeader label="At Risk" sortBy={(datum: any) => datum.status_yellow} />,
            <TableHeader label="Unhealthy" sortBy={(datum: any) => datum.status_red} />,
            <TableHeader label="CTAs" sortBy={(datum: any) => datum.outstanding_ctas} />,
            <TableHeader label="ARR" sortBy={(datum: any) => datum.total_arr} />,
          ]}
        >
          {({ datum }: any) => (
            <TRow key={datum._id?.id}>
              <Cell><Link href={is_csm?"/workloads?csm_id="+datum._id?.id:"/workloads?owner_id="+datum._id?.id}>{datum._id?.name}</Link></Cell>
              <Cell>{datum.total_workloads ? datum.total_workloads : 0}</Cell>
              <Cell><Link href={is_csm?"/workloads?v=on-track&csm_id="+datum._id?.id:"/workloads?v=on-track&owner_id="+datum._id?.id}><Badge variant={"green"}>{datum.status_green ? datum.status_green : 0}</Badge></Link></Cell>
              <Cell><Link href={is_csm?"/workloads?v=at-risk&csm_id="+datum._id?.id:"/workloads?v=at-risk&owner_id="+datum._id?.id}><Badge variant={"yellow"}>{datum.status_yellow ? datum.status_yellow : 0}</Badge></Link></Cell>
              <Cell><Link href={is_csm?"/workloads?v=unhealthy&csm_id="+datum._id?.id:"/workloads?v=unhealthy&owner_id="+datum._id?.id}><Badge variant={"red"}>{datum.status_red ? datum.status_red : 0}</Badge></Link></Cell>
              <Cell>{datum.outstanding_ctas ? datum.outstanding_ctas : 0}</Cell>
              <Cell>{USDollar.format(datum.total_arr)}</Cell>
            </TRow>
          )}
        </Table>
      </Row>

    </ExpandableCard>
    return element
  }

  return renderTeamWorkloads()
}
