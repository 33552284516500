/**
 * Health indicator list is defined in the following google doc
 *  https://docs.google.com/spreadsheets/d/1in8ZOKxK6SEvrBFnLEz4_euRHQX7X3gZHgRG5uJqKCA/edit#gid=2096379398
 */
export const PHASE_INDICATORS = [
    {
      phase: "Onboarding",
      stages: [
        {
          id: "Plan",
          indicators: [
            {
              id: "ind_deployment_ready_health", business_value: "Deployment ready indicates a milestone in the Atlas journey. Once this milestone is hit we believe they in a position to move into a productions state soon. That means we'll want to help make sure they are configured properly and ready for scale. It also gives us the oppotunity to evaluate a forecast for the workload", definition: "Workload exhibits behaviors that we see indicate the are ready to deploy. ", label: "Deployment Ready", metrics: [
                {
                  "id": "sfdc_user_support_access_health", business_value: "BIZ", definition: "No_Definition", label: "sfdc_user_support_access_health",
                },
                {
                  "id": "activation_code_inserted_health", business_value: "BIZ", definition: "No_Definition", label: "activation_code_inserted_health"
                }
              ]
            },
            {
              id: "ind_ui_usage_health", business_value: "Customers using the Atlas console are doing something with Atlas. It could indicate that they're in a build phase and that they're progressing in the customer journey. However, lack of usage doesn't mean that they're not using Atlas, it just means we might want to check in on their experience", definition: "Customers are actively using the Atlas console", label: "UI Usage", metrics: [
                {
                  "id": "ui_sign_in_health", business_value: "BIZ", definition: "No_Definition", label: "ui_sign_in_health",
                }
              ]
            },
            {
              id: "ind_cases_usage_health", business_value: "Allows us to pinpoint WLs or Accounts that have major risk. Ultimately lagging indicator but an important area for GTM to focus.", definition: "High Prioirity tickets submitted to support", label: "Support Usage", metrics: [
                {
                  "id": "cases_fts_health", business_value: "BIZ", definition: "No_Definition", label: "cases_fts_health",
                },
                {
                  "id": "cases_health", business_value: "BIZ", definition: "No_Definition", label: "cases_health",
                },
                {
                  "id": "cases_s1_health", business_value: "BIZ", definition: "No_Definition", label: "cases_s1_health"
                }
              ]
            }
          ]
        },
        {
          id: "Build",
          indicators: [
            {
              id: "ind_usage_trend_health", business_value: "The trend of usage over time will show us if they're progressing in their journey. We'll be able to understand if they're stagnant, growing or shrinking. This can help position us to have different conversations about growth or churn prevention.", definition: "The trend of usage is tracking positively over time. The evidence we see for Active DB Utilization is getting better week over week", label: "DB Usage Trend", metrics: [
                {
                  "id": "usage_trend_data_size_diff_health", label:"usage_trend_data_size_diff_health",
                },
                {
                  "id": "usage_trend_reads_per_sec_wow_pct_health", label:"usage_trend_reads_per_sec_wow_pct_health"
                },
                {
                  "id": "usage_trend_writes_per_sec_wow_pct_health", label:"usage_trend_writes_per_sec_wow_pct_health"
                },
                {
                  "id": "usage_trend_connections_diff_health", label:"usage_trend_connections_diff_health"
                },
                {
                  "id": "usage_trend_alerts_health", label:"usage_trend_alerts_health"
                }
              ]
            },
            {
              id: "ind_db_usage_health", business_value: "Lack of Active DB Utilization is almost certainly not a good outcome as they're not using the product. On the other hand, the type of usage we're seeing can help to diagnose customer satisfaction", definition: "Evidence that shows the workload is being used. Connections are being made, clusters are being created, queries are being run", label: "Active DB Utilization", metrics: [
                {
                  "id": "db_usage_writes_health", business_value: "BIZ", definition: "No_Definition", label: "db_usage_writes_health",
                },
                {
                  "id": "db_usage_conns_health", business_value: "BIZ", definition: "No_Definition", label: "db_usage_conns_health",
                },
                {
                  "id": "db_usage_data_size_health", business_value: "BIZ", definition: "No_Definition", label: "db_usage_data_size_health"
                }
              ]
            },
            {
              id: "ind_ui_usage_health", business_value: "Customers using the Atlas console are doing something with Atlas. It could indicate that they're in a build phase and that they're progressing in the customer journey. However, lack of usage doesn't mean that they're not using Atlas, it just means we might want to check in on their experience", definition: "Customers are actively using the Atlas console", label: "UI Usage", metrics: [
                {
                  "id": "ui_sign_in_health", business_value: "BIZ", definition: "No_Definition", label: "ui_sign_in_health",
                }
              ]
            },
            {
              id: "ind_cases_usage_health", business_value: "Allows us to pinpoint WLs or Accounts that have major risk. Ultimately lagging indicator but an important area for GTM to focus.", definition: "High Prioirity tickets submitted to support", label: "Support Usage", metrics: [
                {
                  "id": "cases_fts_health", label:"cases_fts_health",
                },
                {
                  "id": "cases_health", label:"cases_health",
                },
                {
                  "id": "cases_s1_health", label:"cases_s1_health"
                }
              ]
            }
          ]
        },
        {
          id: "Launch",
          indicators: [
            {
              id: "ind_prod_ready_health", business_value: "Let's us know that they should be ready to launch soon. We should think about the forecast and make sure they're set up to be able to scale (if needed).", definition: "The usage of the database has progressed to a level that aligns with our expectations of a production workload. The prod_cluster flag is true for a given cluster if it exhibits a strong likelihood of production level usage based on overall activity, feature adoption and seasonality", label: "Production Ready", metrics: [
                {
                  "id": "prod_cluster_detected_health", business_value: "BIZ", definition: "No_Definition", label: "prod_cluster_detected_health",
                }
              ]
            },
            {
              id: "ind_usage_trend_health", business_value: "The trend of usage over time will show us if they're progressing in their journey. We'll be able to understand if they're stagnant, growing or shrinking. This can help position us to have different conversations about growth or churn prevention.", definition: "The trend of usage is tracking positively over time. The evidence we see for Active DB Utilization is getting better week over week", label: "DB Usage Trend", metrics: [
                {
                  "id": "usage_trend_data_size_diff_health", label:"usage_trend_data_size_diff_health",
                },
                {
                  "id": "usage_trend_reads_per_sec_wow_pct_health", label:"usage_trend_reads_per_sec_wow_pct_health"
                },
                {
                  "id": "usage_trend_writes_per_sec_wow_pct_health", label:"usage_trend_writes_per_sec_wow_pct_health"
                },
                {
                  "id": "usage_trend_connections_diff_health", label:"usage_trend_connections_diff_health"
                },
                {
                  "id": "usage_trend_alerts_health", label:"usage_trend_alerts_health"
                }
              ]
            },
            {
              id: "ind_db_usage_health", business_value: "Lack of Active DB Utilization is almost certainly not a good outcome as they're not using the product. On the other hand, the type of usage we're seeing can help to diagnose customer satisfaction", definition: "Evidence that shows the workload is being used. Connections are being made, clusters are being created, queries are being run", label: "Active DB Utilization", metrics: [
                {
                  "id": "db_usage_writes_health", business_value: "BIZ", definition: "No_Definition", label: "db_usage_writes_health",
                },
                {
                  "id": "db_usage_conns_health", business_value: "BIZ", definition: "No_Definition", label: "db_usage_conns_health",
                },
                {
                  "id": "db_usage_data_size_health", business_value: "BIZ", definition: "No_Definition", label: "db_usage_data_size_health"
                }
              ]
            },
            {
              id: "ind_cases_usage_health", business_value: "Allows us to pinpoint WLs or Accounts that have major risk. Ultimately lagging indicator but an important area for GTM to focus.", definition: "High Prioirity tickets submitted to support", label: "Support Usage", metrics: [
                {
                  "id": "cases_fts_health", label:"cases_fts_health",
                },
                {
                  "id": "cases_health", label:"cases_health",
                },
                {
                  "id": "cases_s1_health", label:"cases_s1_health"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      phase: "Consumption",
      stages: [
        {
          id: "Consumption",
          indicators: [
            {
              id: "ind_scale_blocking_antipattern_health", business_value: "These represent bottlenecks in the product that if not fixed will cause problems for the customer. They're exteremely important becuase the customer often isn't aware there's a problem so our proactive engagement can save us both from a lot of heartache.", definition: "Features that prevent performance or will increase cost at scale. This is based off a poor data modeling flag - for more details on what's driving this, please refer to <a href='https://tableau.corp.mongodb.com/#/views/DataModelingAntipatternDash/AntipatternDash?:iid=1' target='_blank'>the antipatterns dashboard</a> and examine the impacted clusters. ", label: "Poor Data Model", metrics: [
                {
                  "id": "data_model_poor_flag_health", business_value: "BIZ", definition: "No_Definition", label: "data_model_poor_flag_health",
                }
              ]
            },
            {
              id: "ind_open_alerts_health", business_value: "Pending items for a customer means that we need to give them more attention to close out any requests they have.", definition: "Any open tickets or items that were flagged in Atlas UI which remain unresolved.", label: "Open Alerts", metrics: [{
                "id": "unresolved_alerts_health", business_value: "BIZ", definition: "No_Definition", label: "unresolved_alerts_health",
              }]
            },
            {
              id: "ind_scaling_events_health", business_value: "When we see change in scale, that allows to ask the question, \"why\"? We'll want to know if the scaling is intended", definition: "Usage is going up or down", label: "Scale Up / Down", metrics: [
                {
                  "id": "num_scale_down_event_15d_health", business_value: "BIZ", definition: "No_Definition", label: "num_scale_down_event_15d_health",
                },
                {
                  "id": "num_scale_up_event_15d_health", business_value: "BIZ", definition: "No_Definition", label: "num_scale_up_event_15d_health"
                }
              ]
            },
            {
              id: "ind_usage_trend_health", business_value: "The trend of usage over time will show us if they're progressing in their journey. We'll be able to understand if they're stagnant, growing or shrinking. This can help position us to have different conversations about growth or churn prevention.", definition: "The trend of usage is tracking positively over time. The evidence we see for Active DB Utilization is getting better week over week", label: "DB Usage Trend", metrics: [
                {
                  "id": "usage_trend_data_size_diff_health", label:"usage_trend_data_size_diff_health",
                },
                {
                  "id": "usage_trend_reads_per_sec_wow_pct_health", label:"usage_trend_reads_per_sec_wow_pct_health"
                },
                {
                  "id": "usage_trend_writes_per_sec_wow_pct_health", label:"usage_trend_writes_per_sec_wow_pct_health"
                },
                {
                  "id": "usage_trend_connections_diff_health", label:"usage_trend_connections_diff_health"
                },
                {
                  "id": "usage_trend_alerts_health", label:"usage_trend_alerts_health"
                }
              ]
            },
            {
              id: "ind_db_usage_health", business_value: "Lack of Active DB Utilization is almost certainly not a good outcome as they're not using the product. On the other hand, the type of usage we're seeing can help to diagnose customer satisfaction", definition: "Evidence that shows the workload is being used. Connections are being made, clusters are being created, queries are being run", label: "Active DB Utilization", metrics: [
                {
                  "id": "db_usage_writes_health", business_value: "BIZ", definition: "No_Definition", label: "db_usage_writes_health",
                },
                {
                  "id": "db_usage_conns_health", business_value: "BIZ", definition: "No_Definition", label: "db_usage_conns_health",
                },
                {
                  "id": "db_usage_data_size_health", business_value: "BIZ", definition: "No_Definition", label: "db_usage_data_size_health"
                }
              ]
            },
            {
              id: "ind_cases_health", business_value: "Allows us to pinpoint WLs or Accounts that have major risk. Ultimately lagging indicator but an important area for GTM to focus.", definition: "High Prioirity tickets submitted to support", label: "Support Usage", metrics: [
                {
                  "id": "cases_fts_health", label:"cases_fts_health",
                },
                {
                  "id": "cases_health", label:"cases_health",
                },
                {
                  "id": "cases_s1_health", label:"cases_s1_health"
                }
              ]
            },
            // 12-9-2023; WLH SEARCH INDICATOR WORKSTREAM
            {
              id: "ind_search_health", business_value: "We want to ensure that customers can use this product to effectively build on their developer data platform", definition: "Customer purchased search product, while they are using clusters they not activating search", label: "Search Adoption", metrics: [
                {
                  "id": "search_active_usage_health", label:"search_active_usage_health",
                },
              ]
            }
          ]
        }
      ]
    }
  ]
  
  export const STAGES_PHASES = {
    "Plan": "Onboarding",
    "Build": "Onboarding",
    "Launch": "Onboarding",
    "Consumption": "Consumption"
  }